/* eslint-disable no-loop-func */
import React from 'react';
import './App.css';
import '@eds/vanilla/eds.min.css';


const RED = 'rgb(255, 0, 0)'
const GREEN = 'rgb(0, 255, 0)'

// define all inference type of sample images, same keyword in FileUploader, key the same index with reqSvgs[] in constructor functions below.
const nonGroupingUC = ['Antenna_Coverage_Area_Sample','Antenna_Front_Facing_Sample','PPE_Safety_Checking_Sample', 'Antenna_Weatherproofing_Sample', 'Radio_Unused_RF_Port_Weatherproofing_Sample', 'ROI_Sample','Grounding_Sample','Antenna_Mounting_Sample','Baseband_Mounting_Sample','Baseband_Grounding_Sample', 'GPS_Cable_Routing_Sample','GPS_Antenna_Grounding_Sample','Roxtec_Defect_Sample',
'Site_Data_Extraction_From_Image_Sample', 'Thermometer_Reading_Sample', 'Door_Status_Checking_Sample', 'Battery_Defect_Sample', 'Tower_Installed_Equipment_Recognition_Sample', 'Tower_Type_Recognition_Sample', 'Meter_Reading_Sample', 'Nameplate_Data_Recognition_Sample','Tower_Installed_Equipment_Defect_Sample',
'Cabinet_Installed_Equipment_Recognition_Sample','Cabinet_Installed_Equipment_Defect_Sample','GPS_Main_Grounding_Sample','Antenna_Unused_RF_Port_Count_Sample','GPS_Mounting_Sample', 'GPS_Weatherproofing_Sample','Radio_Mounting_Sample','Radio_Weatherproofing_Sample','Blurred_Image_Sample','Antenna_Unused_RF_Port_Weatherproofing_Sample',
'Radio_Unused_RF_Port_Count_Sample','Antenna_Mounting_Torque_Mark_Checking_Sample','PPE_Helmet_Vest_Shoe_Sample', 'Vehicle_Odometer_Sample','Baseband_Mounting_Ext_Sample','Baseband_Grounding_Ext_Sample','PPE_Helmet_Harness_Gloves_Shoes_Sample','Antenna_Unused_Port_Sealing_Sample','Radio_Unused_Port_Sealing_Sample','Loose_Hardware_Sample','Level_Check_Sample','Unistrut_End_Cap_Sample','Manufacture_Label_Reading_Sample',
'Baseband_GPS_Cable_Ext_Sample','Baseband_Unused_Port_Sealing_Sample','Emergency_Plan_Banner_Sample','PPE_Helmet_Shoes_Sample','Power_Supply_Unit_Mounting_Sample','Vin_Extraction_Sample','Generic_OCR_Sample','Cable_Clamp_Checking_Sample','Zeus_TowerID_Extraction_Sample','Grounding_Busbar_Sample','Tilt_Meter_Presence_Sample','QR_And_Barcode_Reader_Sample','Passive_Tagging_Sample',
'Antenna_Label_Reading_Sample','QR_And_Barcode_Available_Sample','Circuit_Breaker_Connection_Sample','Sticker_Reading_Sample','Busbar_Front_Sample','Color_Sticker_Sample','Jumper_Color_Code_Sample','Azimuth_Reading_Sample','Cable_Tie_Checking_Sample','Sector_Assembly_Sample','Tape_Measure_Presence_Sample','Antenna_Height_Reading_Sample','Cable_Run_Checking_Sample',
'MCB_Detection_Sample','MW_Dish_Mounting_Sample','DDF_Detection_Sample','Site_Entrance_Checking_Sample','Battery_Presence_Sample','KWH_Meter_Presence_Sample','Site_Access_Detection_Sample','Foundation_Of_Cabinet_Sample','Cabling_Inside_Cabinet_Sample','PPE_Rigger_Video_Sample','Rectifier_Detection_Sample','LLM_Site_Access_Detection_Sample',
'Vertical_Power_Cable_Color_Code_Sample','Cable_Inlet_Presence_Sample','PPE_Rigger_And_Safety_Checking_Sample','Antenna_Weatherproofing_And_Unused_Port_Sealing_Sample','Radio_Weatherproofing_And_Unused_Port_Sealing_Sample','Radio_Grounding_Sample','PPE_Climbing_Checking_Video_Sample','LLM_Spare_1_Sample']

const groupingUC = ['Baseframe_Cabinet_Mounting_Grouping_6_Sample','PPE_Rigger_Sample','Vehicle_Light_Windshield_Licenseplate_Sample','Vehicle_Tyre_Sample','Vehicle_Airbags_Sample','Vehicle_Seatbelts_Sample','First_Aid_Box_Sample','Fire_Extinguisher_Sample','PPE_Electrical_Sample','Hand_Held_Tools_Checking_Sample','Rigging_Kit_Checking_Sample','Rigging_Kit_1_Pulley_Sample','Rigging_Kit_2_Pulley_Sample',
'Rigging_Kit_3_Pulley_Sample','Antenna_Grounding_Grouping_2_Sample','Radio_Grounding_Grouping_2_Sample','GPS_Grounding_Grouping_2_Sample','Baseband_Grounding_Grouping_3_Sample','Baseband_Mounting_Grouping_3_Sample',
'Antenna_Mounting_Grouping_3_Sample','Radio_Mounting_Grouping_3_Sample','PPE_Rigger_Without_Vest_Sample','Crane_Drop_Zone_Sample','Crane_Position_Sample','MW_Dish_Grounding_G2_Sample','Caution_Tape_Checking_Sample','SRS_Screenshot_Grouping_4_Sample','Cabinet_Leveling_Grouping_3_Sample','TRM_Mounting_Grouping_3_Sample','LLM_Antenna_Azimuth_Validation_Sample',
'RBS_Main_Grounding_Grouping_2_Sample','Baseband_Mounting_And_Unused_Port_Checking_Grouping_3_Sample','Earthing_Kit_And_Grounding_Grouping_5_Sample','MW_RAU_Weatherproofing_Grouping_N_Sample','Indoor_TRM_Mounting_And_Unused_Ports_Checking_Grouping_3_Sample','MW_Dish_Small_Size_Mounting_Grouping_7_Sample','MW_RAU_Grounding_Grouping_N_Sample','MW_Dish_Arm_Mounting_G4_Sample']
const sample_image_map = nonGroupingUC.concat(groupingUC);
const sample_doc_map = ['Safe_Work_Info_Verify_Sample']
const sample_pdf_image_map = ['LLM_Antenna_Azimuth_Validation_Sample']
const doc_URL_mapping = {"SIKA_22-12-2023_Pagi_PT SINERGI AITIKOM_12JKS0768 _MASJIDALFAJRI_CG _Angga Setya Dharma":"https://cgcmdop.blob.core.windows.net/vi-test/SIKA_22-12-2023_Pagi_PT%20SINERGI%20AITIKOM_12JKS0768%20_MASJIDALFAJRI_CG%20_Angga%20Setya%20Dharma.pdf?sp=r&st=2023-12-22T08:03:19Z&se=2024-12-22T16:03:19Z&spr=https&sv=2022-11-02&sr=b&sig=GoVqJ4drNQEQTre6VNDxqznyruqmZZXVdNTRhahwKHk%3D",
"SIKA_22-12-2023_Pagi_PT JAVA UNITED SERVICES_13CBN0192_SITUUDIK_CIBUNGBULANG_PL_Rianto":"https://cgcmdop.blob.core.windows.net/vi-test/SIKA_22-12-2023_Pagi_PT%20JAVA%20UNITED%20SERVICES_13CBN0192_SITUUDIK_CIBUNGBULANG_PL_Rianto.pdf?sp=r&st=2023-12-22T08:02:31Z&se=2024-12-21T16:02:31Z&spr=https&sv=2022-11-02&sr=b&sig=gAy%2FwKP28QPZF0PKxun4%2FgL04CktPdaBGRdTAf1nZDE%3D",
"SIKA_22-12-2023_Pagi_PT INDO HUMAN RESOURCE_4457362E_Karangtanjung lemahabang_Adi Sasmita":"https://cgcmdop.blob.core.windows.net/vi-test/SIKA_22-12-2023_Pagi_PT%20INDO%20HUMAN%20RESOURCE_4457362E_Karangtanjung%20lemahabang_Adi%20Sasmita.pdf?sp=r&st=2023-12-22T08:01:03Z&se=2024-12-21T16:01:03Z&spr=https&sv=2022-11-02&sr=b&sig=1fWmHP%2FnFaIMoZFhvpZ774ERO1ij6vTBu%2F1yFDk0Jis%3D",
"SIKA_22-12-2023_Pagi_PT EVANTAMA NETWORK SYSTEM_13CBN0047_KALONGSAWAH CBN2 EP_Muhammad Razi Luthfi Hadi":"https://cgcmdop.blob.core.windows.net/vi-test/SIKA_22-12-2023_Pagi_PT%20EVANTAMA%20NETWORK%20SYSTEM_13CBN0047_KALONGSAWAH%20CBN2%20EP_Muhammad%20Razi%20Luthfi%20Hadi.pdf?sp=r&st=2023-12-22T08:00:13Z&se=2024-12-21T16:00:13Z&spr=https&sv=2022-11-02&sr=b&sig=9Y4IDgSdf0qiiGPFDcBGQx4l9BrAEodxBQ9oaSQHzIE%3D"}

function getType(file){
    var filename=file;
    var index1=filename.lastIndexOf(".");
    var index2=filename.length;
    var type=filename.substring(index1,index2);
    return type;
}

export default class OriginView extends React.Component {
    constructor(props) {
        super(props)
        this.canvasRef = React.createRef();
        this.videoRef = React.createRef();
        this.width = 1080;
        this.height = 700;
        this.SampleImagesArr=[];
        this.SampleDocsArr=[];
        this.SampleImageDocArr = [];
        this.currentSampleSrc= '';
        this.imgNumber=0;
        this.docNumer=0;
        this.PDFurl = "";
        this.timestampList = [];
        this.labelTimestampDict = {};
        this.boxData = [];
        this.ratio = 1;
        this.offsetX = 0;
        this.offsetY = 0;

        this.state = {
            imgIndex: -1,
            targetImagesIndex: 0,
            docIndex: -1,
            targetDocsIndex: 0,
            imgsDocsIndex: -1,
            targetImagesDocsIndex: 0
            //isFirstRender: true
        }

        function scangroup(allSvg) {
            var groups = []
            for (var k = 0; k < allSvg.length; k++)
               {
                var DirName = allSvg[k] == '' ? '' : allSvg[k].substring(0, allSvg[k].lastIndexOf('/'));
               // console.log(DirName)   
                if (DirName.indexOf('group') && !groups.includes(DirName)) {
                  groups.push(DirName)
                  //console.log(groups)
                  }       
                }
            return groups
            }
            function pushImagesToGroup(groupForUC,allSvgFilepaths,item,images){
                console.log(groupForUC.length)
                for (var n=0; n< groupForUC.length; n++){
                    var imagesOfSubUC = new Array()
                    for (var m = 0 ;m< allSvgFilepaths.length; m++){ 
                        console.log(allSvgFilepaths[m]);
                      if(allSvgFilepaths[m].includes(groupForUC[n])){
                        imagesOfSubUC.push(reqSvgs[item](allSvgFilepaths[m]))
                      }
                    }
                    console.log(imagesOfSubUC);
                    if(imagesOfSubUC.length !== 0){
                   images.push(imagesOfSubUC)  
                    }
                }
               }

        // load all sample docs under target files
        // this doc path have to be hardcoded, load in compile phase
        var reqDocSvgs=[]
        reqDocSvgs[0] = require.context('./doc/sika', true, /(\.pdf|\.PDF)$/ )
        for (let item = 0;item < reqDocSvgs.length; item++){
            var allSvgDocFilepaths = reqDocSvgs[item].keys();
            console.log("reqSvgskeys:"+allSvgDocFilepaths)
            var docs=new Array()
            for (var i = 0 ;i< allSvgDocFilepaths.length; i++)
            {
                docs[i] = reqDocSvgs[item](allSvgDocFilepaths[i]);
                console.log("docs:"+docs[i]);
            }
            this.SampleDocsArr.push(docs)
            console.log(this.SampleDocsArr)
        }
             
        // load all sample images under target files
        // this img path have to be hardcoded, load in compile phase
        var reqSvgsForNonGroupingUC=[];
        reqSvgsForNonGroupingUC[0] = require.context('./img/antennaTop', true, /\.jpg$/);
        reqSvgsForNonGroupingUC[1] = require.context('./img/antennaFace', true, /\.jpg$/);
        reqSvgsForNonGroupingUC[2] = require.context('./img/safetyChecking', true, /\.jpg$/);
        reqSvgsForNonGroupingUC[3] = require.context('./img/antennaWP', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[4] = require.context('./img/radioUnusedPort', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[5] = require.context('./img/roi', true, /\.jpg$/ );
        reqSvgsForNonGroupingUC[6] = require.context('./img/grounding', true, /\.jpg$/ );
        reqSvgsForNonGroupingUC[7] = require.context('./img/antennaFixation', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[8] = require.context('./img/mmeaBBFixation', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[9] = require.context('./img/mmeaBBGND', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[10] = require.context('./img/mmeaGPSRouting', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[11] = require.context('./img/mmeaGPSGND', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[12] = require.context('./img/mmeaRoxtec', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[13] = require.context('./img/siteDataExtract', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[14] = require.context('./img/thermometer', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[15] = require.context('./img/door', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[16] = require.context('./img/battery', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[17] = require.context('./img/towerInstalledEquipmentRecognition', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[18] = require.context('./img/towerType', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[19] = require.context('./img/powermeter', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[20] = require.context('./img/nameplate', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[21] = require.context('./img/towerInstalledEquipmentDefect', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[22] = require.context('./img/cabinet', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[23] = require.context('./img/cabinet_bolts_cable', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[24] = require.context('./img/mmeaGPSGND1', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[25] = require.context('./img/antennaunusedrfp_count', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[26] = require.context('./img/gpsfixation', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[27] = require.context('./img/gpsweatherproofing', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[28] = require.context('./img/radioFixation', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[29] = require.context('./img/radioWP', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[30] = require.context('./img/focus', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[31] = require.context('./img/antennaUnusedPort', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[32] = require.context('./img/radiounusedRFportCount', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/  );
        reqSvgsForNonGroupingUC[33] = require.context('./img/torque_mark_checking', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[34] = require.context('./img/common-ppe-samples', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[35] = require.context('./img/odometer', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[36] = require.context('./img/mnea_BB_fixation', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[37] = require.context('./img/mnea_baseband_grounding', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[38] = require.context('./img/ppe_helmet_harness_gloves_shoes', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForNonGroupingUC[39] = require.context('./img/antenna_unused_port_sealing', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForNonGroupingUC[40] = require.context('./img/radio_unused_port_sealing', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForNonGroupingUC[41] = require.context('./img/loose_hardware', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForNonGroupingUC[42] = require.context('./img/level_check', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForNonGroupingUC[43] = require.context('./img/unistrut', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForNonGroupingUC[44] = require.context('./img/manufacture_label_reading', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);  
        reqSvgsForNonGroupingUC[45] = require.context('./img/baseband_gps_line', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForNonGroupingUC[46] = require.context('./img/baseband_unused_port_sealing', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForNonGroupingUC[47] = require.context('./img/emergency_plan_banner', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[48] = require.context('./img/ppe_helmet_shoes', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[49] = require.context('./img/power_supply_unit', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[50] = require.context('./img/vin_reading', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[51] = require.context('./img/generic_reading', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[52] = require.context('./img/cable_clamp_checking', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[53] = require.context('./img/zeus_towerID_extraction', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[54] = require.context('./img/baseband-grounding-busbar', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[55] = require.context('./img/tilt-meter-presence', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[56] = require.context('./img/barcode-reader', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[57] = require.context('./img/passive-tagging', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[58] = require.context('./img/antenna-label-reading', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[59] = require.context('./img/barcode-presence', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[60] = require.context('./img/circuit-breaker-connection', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[61] = require.context('./img/sticker-reading', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[62] = require.context('./img/busbar-front', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[63] = require.context('./img/color-sticker', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[64] = require.context('./img/jumper-color-code', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[65] = require.context('./img/azimuth-reading', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[66] = require.context('./img/cable-tie-checking', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[67] = require.context('./img/sector-assembly', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[68] = require.context('./img/tape-measure-presence', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[69] = require.context('./img/antenna-height-reading', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[70] = require.context('./img/cable-run-checking', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[71] = require.context('./img/mcb-detection', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[72] = require.context('./img/mw-dish-mounting', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[73] = require.context('./img/ddf-detection', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[74] = require.context('./img/site-entrance-checking', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[75] = require.context('./img/battery-presence', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[76] = require.context('./img/kwh-meter-presence', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[77] = require.context('./img/site-access-detection', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[78] = require.context('./img/foundation-of-cabinet', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[79] = require.context('./img/cabling-inside-cabinet', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForNonGroupingUC[80] = require.context('./img/ppe-rigger-video', true, /(\.mp4)$/ );
        reqSvgsForNonGroupingUC[81] = require.context('./img/rectifier-detection', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForNonGroupingUC[82] = require.context('./img/llm-site-access-detection', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForNonGroupingUC[83] = require.context('./img/vertical-power-cable-color-code-presence', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForNonGroupingUC[84] = require.context('./img/cable-inlet-presence', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForNonGroupingUC[85] = require.context('./img/ppe-rigger-and-safety-checking-video', true, /(\.mp4)$/ );
        reqSvgsForNonGroupingUC[86] = require.context('./img/antenna-weatherproofing-and-unused-port-sealing', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForNonGroupingUC[87] = require.context('./img/radio-weatherproofing-and-unused-port-sealing', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForNonGroupingUC[88] = require.context('./img/radio_grounding', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForNonGroupingUC[89] = require.context('./img/pre-climbing-checking-video', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForNonGroupingUC[90] = require.context('./img/llm-s-spare-1', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        
        var reqSvgsForGroupingUC=[];
        reqSvgsForGroupingUC[0] = require.context('./img/baseframe-cabinet-mounting', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[1] = require.context('./img/rigger-ppe-samples', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[2] = require.context('./img/vehicle-light-windshield-licenseplate', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[3] = require.context('./img/tyre', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[4] = require.context('./img/airbag', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[5] = require.context('./img/seatbelt', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[6] = require.context('./img/first_aid_box', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[7] = require.context('./img/fire_extinguisher', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[8] = require.context('./img/ppe_electrical', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[9] = require.context('./img/hand_held_tools', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[10] = require.context('./img/rigging_kit', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[11] = require.context('./img/rigging_kit_1_pulley', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[12] = require.context('./img/rigging_kit_2_pulley', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[13] = require.context('./img/rigging_kit_3_pulley', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[14] = require.context('./img/antenna-grounding-g2', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[15] = require.context('./img/radio_grounding_g2', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[16] = require.context('./img/mmeaGPSGNDGroup', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[17] = require.context('./img/BasebandGNDGroup', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[18] = require.context('./img/BasebandFixationGroup', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[19] = require.context('./img/antennaFixationGroup', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[20] = require.context('./img/radioFixationGroup', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[21] = require.context('./img/ppe_rigger_without_vest', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[22] = require.context('./img/crane-drop-zone', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[23] = require.context('./img/crane-position', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[24] = require.context('./img/mw-dish-grounding-g2', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/ );
        reqSvgsForGroupingUC[25] = require.context('./img/caution_tape_checking', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForGroupingUC[26] = require.context('./img/srs-screenshot-detection', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForGroupingUC[27] = require.context('./img/cabinet-leveling', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForGroupingUC[28] = require.context('./img/trm-mounting-g3', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForGroupingUC[29] = require.context('./img/antenna-azimuth-validation', true, /(\.pdf|\.PDF|\.jpg|\.jpeg|\.JPG|\.png|\.PNG)$/);
        reqSvgsForGroupingUC[30] = require.context('./img/rbs-main-grounding-g2', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForGroupingUC[31] = require.context('./img/baseband-mounting-and-unused-port-checking-g3', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForGroupingUC[32] = require.context('./img/earthing-kit-and-grounding', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForGroupingUC[33] = require.context('./img/mw-rau-weatherproofing', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForGroupingUC[34] = require.context('./img/indoor-trm-mounting-and-unused-ports-checking-g3', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        reqSvgsForGroupingUC[35] = require.context('./img/mw-dish-small-size-mounting-g7', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );    
        reqSvgsForGroupingUC[36] = require.context('./img/mw-rau-grounding-gn', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );     
        reqSvgsForGroupingUC[37] = require.context('./img/mw-dish-arm-mounting-g4', true, /(\.jpg|\.jpeg|\.JPG|\.png|\.PNG|\.jfif)$/ );
        
        var allSvgFilepathsForBaseframeCabinetMounting = reqSvgsForGroupingUC[0].keys();
        var allSvgFilepathsForRigger = reqSvgsForGroupingUC[1].keys();
        var allSvgFilepathsForVehicleLight = reqSvgsForGroupingUC[2].keys();
        var allSvgFilepathsForVehicleTyre = reqSvgsForGroupingUC[3].keys();
        var allSvgFilepathsForVehicleAirbag = reqSvgsForGroupingUC[4].keys();
        var allSvgFilepathsForVehicleSeatbelts = reqSvgsForGroupingUC[5].keys();
        var allSvgFilepathsForFirstAidBox = reqSvgsForGroupingUC[6].keys();
        var allSvgFilepathsForFireExtinguisher = reqSvgsForGroupingUC[7].keys();
        var allSvgFilepathsForPPEElectrical = reqSvgsForGroupingUC[8].keys();
        var allSvgFilepathsForHandHeldTools = reqSvgsForGroupingUC[9].keys();
        var allSvgFilepathsForRiggingKit = reqSvgsForGroupingUC[10].keys();
        var allSvgFilepathsForRiggingKit1pulley = reqSvgsForGroupingUC[11].keys();
        var allSvgFilepathsForRiggingKit2pulley = reqSvgsForGroupingUC[12].keys();
        var allSvgFilepathsForRiggingKit3pulley = reqSvgsForGroupingUC[13].keys();
        var allSvgFilepathsForAntennaGND = reqSvgsForGroupingUC[14].keys();
        var allSvgFilepathsForRadioGND = reqSvgsForGroupingUC[15].keys();
        var allSvgFilepathsForGPSGND = reqSvgsForGroupingUC[16].keys();
        var allSvgFilepathsForBasebandGND= reqSvgsForGroupingUC[17].keys();
        var allSvgFilepathsForBasebandFixation= reqSvgsForGroupingUC[18].keys();
        var allSvgFilepathsForAntennaFixation= reqSvgsForGroupingUC[19].keys();
        var allSvgFilepathsForRadioFixation= reqSvgsForGroupingUC[20].keys();
        var allSvgFilepathsForRiggerWithoutVest= reqSvgsForGroupingUC[21].keys();
        var allSvgFilepathsForCraneDropZone= reqSvgsForGroupingUC[22].keys();
        var allSvgFilepathsForCranePosition= reqSvgsForGroupingUC[23].keys();
        var allSvgFilepathsForMWDishGrounding= reqSvgsForGroupingUC[24].keys();
        var allSvgFilepathsForCautionTapeChecking= reqSvgsForGroupingUC[25].keys();
        var allSvgFilepathsForSRSDetection= reqSvgsForGroupingUC[26].keys();
        var allSvgFilepathsForCabinetLeveling= reqSvgsForGroupingUC[27].keys();
        var allSvgFilepathsForTRMMounting= reqSvgsForGroupingUC[28].keys();
        var allSvgFilepathsForAntennaDesignValidation= reqSvgsForGroupingUC[29].keys();
        var allSvgFilepathsForRBSMainGrounding= reqSvgsForGroupingUC[30].keys();
        var allSvgFilepathsForBasebandMountingAndUnusedPortChecking= reqSvgsForGroupingUC[31].keys();
        var allSvgFilepathsForEarthingKitAndGrouding= reqSvgsForGroupingUC[32].keys();
        var allSvgFilepathsForMWRAUWeatherproofing= reqSvgsForGroupingUC[33].keys();
        var allSvgFilepathsForIndoorMWMountingAndUnusedPortsChecking= reqSvgsForGroupingUC[34].keys();
        var allSvgFilepathsForMWDishSmallSizeMounting= reqSvgsForGroupingUC[35].keys();
        var allSvgFilepathsForMWRAUGrounding= reqSvgsForGroupingUC[36].keys();
        var allSvgFilepathsForMWDishArmMounting= reqSvgsForGroupingUC[37].keys();
  
       var groupForBaseFrameCabinetMounting = scangroup(allSvgFilepathsForBaseframeCabinetMounting)
       var groupForRigger = scangroup(allSvgFilepathsForRigger)
       var groupForVehicleLight = scangroup(allSvgFilepathsForVehicleLight)
       var groupForVehicleTyre = scangroup(allSvgFilepathsForVehicleTyre)
       var groupForVehicleAirbag = scangroup(allSvgFilepathsForVehicleAirbag)
       var groupForVehicleSeatbelts = scangroup(allSvgFilepathsForVehicleSeatbelts)
       var groupForFirstAidBox = scangroup(allSvgFilepathsForFirstAidBox)
       var groupForFireExtinguisher = scangroup(allSvgFilepathsForFireExtinguisher)
       var groupForPPEElectrical = scangroup(allSvgFilepathsForPPEElectrical)
       var groupForHandHeldTools = scangroup(allSvgFilepathsForHandHeldTools)
       var groupForRiggingKit = scangroup(allSvgFilepathsForRiggingKit)
       var groupForRiggingKit1pulley = scangroup(allSvgFilepathsForRiggingKit1pulley)
       var groupForRiggingKit2pulley = scangroup(allSvgFilepathsForRiggingKit2pulley)
       var groupForRiggingKit3pulley = scangroup(allSvgFilepathsForRiggingKit3pulley)
       var groupForAntennaGND = scangroup(allSvgFilepathsForAntennaGND)
       var groupForRadioGND = scangroup(allSvgFilepathsForRadioGND)
       var groupForGPSGND = scangroup(allSvgFilepathsForGPSGND)
       var groupForBasebandGND = scangroup(allSvgFilepathsForBasebandGND)
       var groupForBasebandFixation = scangroup(allSvgFilepathsForBasebandFixation)
       var groupForAntennaFixation = scangroup(allSvgFilepathsForAntennaFixation)
       var groupForRadioFixation = scangroup(allSvgFilepathsForRadioFixation)
       var groupForRiggerWithoutVest = scangroup(allSvgFilepathsForRiggerWithoutVest)
       var groupForCraneDropZone = scangroup(allSvgFilepathsForCraneDropZone)
       var groupForCranePosition = scangroup(allSvgFilepathsForCranePosition)
       var groupForMWDishGrounding = scangroup(allSvgFilepathsForMWDishGrounding)
       var groupForCautionTapeChecking = scangroup(allSvgFilepathsForCautionTapeChecking)
       var groupForSRSDetection = scangroup(allSvgFilepathsForSRSDetection)
       var groupForCabinetLeveling = scangroup(allSvgFilepathsForCabinetLeveling)
       var groupForTRMMounting = scangroup(allSvgFilepathsForTRMMounting)
       var groupForAntennaDesignValidation = scangroup(allSvgFilepathsForAntennaDesignValidation)
       var groupForRBSMainGrounding = scangroup(allSvgFilepathsForRBSMainGrounding)
       var groupForBasebandMountingAndUnusedPortChecking = scangroup(allSvgFilepathsForBasebandMountingAndUnusedPortChecking)
       var groupForEarthingKitAndGrounding = scangroup(allSvgFilepathsForEarthingKitAndGrouding)
       var groupForMWRAUWeatherproofing = scangroup(allSvgFilepathsForMWRAUWeatherproofing)
       var groupForIndoorMWMountingAndUnusedPortsChecking = scangroup(allSvgFilepathsForIndoorMWMountingAndUnusedPortsChecking)
       var groupForMWDishSmallSizeMounting = scangroup(allSvgFilepathsForMWDishSmallSizeMounting)
       var groupForMWRAUGrounding = scangroup(allSvgFilepathsForMWRAUGrounding)
       var groupForMWDishArmMounting = scangroup(allSvgFilepathsForMWDishArmMounting)
       
       const reqSvgs = reqSvgsForNonGroupingUC.concat(reqSvgsForGroupingUC);
       let baseIndex = reqSvgs.length - reqSvgsForGroupingUC.length;
       
        for (let item = 0;item < reqSvgs.length; item++){
            var allSvgFilepaths = reqSvgs[item].keys();
            console.log(item)
            console.log("reqSvgskeys:"+allSvgFilepaths)
            var images=new Array()
            if (item < baseIndex){
            for (var i = 0 ;i< allSvgFilepaths.length; i++)
            {
                images[i] = reqSvgs[item](allSvgFilepaths[i]);
                //console.log("images:"+images[i]);

            }
           }   
            if(item === baseIndex){///for baseframe cabinet mounting
            pushImagesToGroup(groupForBaseFrameCabinetMounting,allSvgFilepaths,item,images)
           }
            if(item === baseIndex + 1){///for rigger ppe
               pushImagesToGroup(groupForRigger,allSvgFilepaths,item,images)
            }
            if(item === baseIndex + 2){///for vehicle light windshield licenseplate 
                pushImagesToGroup(groupForVehicleLight,allSvgFilepaths,item,images)
            }
            if(item === baseIndex + 3){///for vehicle tyre
                pushImagesToGroup(groupForVehicleTyre,allSvgFilepaths,item,images)
            }
            if(item === baseIndex + 4){///for vehicle airbag
                pushImagesToGroup(groupForVehicleAirbag,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 5){///for vehicle seatbelts
                pushImagesToGroup(groupForVehicleSeatbelts,allSvgFilepaths,item,images)   
            }
            if(item === baseIndex + 6){///for first aid box
                pushImagesToGroup(groupForFirstAidBox,allSvgFilepaths,item,images)   
            }
            if(item === baseIndex + 7){///for fire extinguisher
                pushImagesToGroup(groupForFireExtinguisher,allSvgFilepaths,item,images)     
            }
            if(item === baseIndex + 8){///for ppe electrical
                pushImagesToGroup(groupForPPEElectrical,allSvgFilepaths,item,images)     
            }
            if(item === baseIndex + 9){///for hand held tools
                pushImagesToGroup(groupForHandHeldTools,allSvgFilepaths,item,images)   
            }
            if(item === baseIndex + 10){///for rigging kit
                pushImagesToGroup(groupForRiggingKit,allSvgFilepaths,item,images)    
            }
            if(item === baseIndex + 11){///for rigging kit pulley 1
                pushImagesToGroup(groupForRiggingKit1pulley,allSvgFilepaths,item,images)  
            }
            if(item === baseIndex + 12){///for rigging kit pulley 2
                pushImagesToGroup(groupForRiggingKit2pulley,allSvgFilepaths,item,images)  
            }
            if(item === baseIndex + 13){///for rigging kit pulley 3
                pushImagesToGroup(groupForRiggingKit3pulley,allSvgFilepaths,item,images)   
            }
            if(item === baseIndex + 14){///for antenna grounding
                pushImagesToGroup(groupForAntennaGND,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 15){///for radio grounding
                pushImagesToGroup(groupForRadioGND,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 16){///for gps grounding
                pushImagesToGroup(groupForGPSGND,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 17){///for gps grounding
                pushImagesToGroup(groupForBasebandGND,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 18){///for gps grounding
                pushImagesToGroup(groupForBasebandFixation,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 19){///for gps grounding
                pushImagesToGroup(groupForAntennaFixation,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 20){///for gps grounding
                pushImagesToGroup(groupForRadioFixation,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 21){///for rigger without vest
                pushImagesToGroup(groupForRiggerWithoutVest,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 22){///for crane drop zone
                pushImagesToGroup(groupForCraneDropZone,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 23){///for crane position
                pushImagesToGroup(groupForCranePosition,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 24){///for mw dish grounding
                pushImagesToGroup(groupForMWDishGrounding,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 25){///for caution tape checking
                pushImagesToGroup(groupForCautionTapeChecking,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 26){///for srs screen shot detection
                pushImagesToGroup(groupForSRSDetection,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 27){///for cabinet leveling
                pushImagesToGroup(groupForCabinetLeveling,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 28){///for TRM mounting
                pushImagesToGroup(groupForTRMMounting,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 29){///for antenna design validation
                pushImagesToGroup(groupForAntennaDesignValidation,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 30){///for RBS main grounding
                pushImagesToGroup(groupForRBSMainGrounding,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 31){///for basebandmounting and unused port checking
                pushImagesToGroup(groupForBasebandMountingAndUnusedPortChecking,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 32){///for earting kit and grounding
                pushImagesToGroup(groupForEarthingKitAndGrounding,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 33){///for MW RAU weatherproofing
                pushImagesToGroup(groupForMWRAUWeatherproofing,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 34){///for Indoor MW Mounting and Unused ports checking
                pushImagesToGroup(groupForIndoorMWMountingAndUnusedPortsChecking,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 35){///for MW dish small size mounting
                pushImagesToGroup(groupForMWDishSmallSizeMounting,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 36){///for MW rau grounding
                pushImagesToGroup(groupForMWRAUGrounding,allSvgFilepaths,item,images) 
            }
            if(item === baseIndex + 37){///for MW dish arm mounting
                pushImagesToGroup(groupForMWDishArmMounting,allSvgFilepaths,item,images) 
            }
            
            console.log("images："+images)
            this.SampleImagesArr.push(images)
            //console.log(this.SampleImagesArr)
        }    
    }
    targetImagesIndex
    targetDocIndex
    
    componentDidMount() {
        this.setState({ imgIndex: -1 });
        this.setState({ docIndex: -1 });
    }

    componentDidUpdate(prevProps, prevState) {
        const file = this.props.imgSrc;
        const fileName = this.props.fileName;
        const sampleSrc = this.props.imgSample;
        const videoURL = this.props.videoURL;
        const detectionR = this.props.imgDetection ? this.props.imgDetection : undefined;
        const selectedLabels = this.props.selectedLabels;
        let imageNames = [];
        let imageRatio = [];
        let imageOffsetX = [];
        let imageOffsetY = [];
        let canvas_width = 1080;
        let canvas_height = 700;
        console.log(videoURL)
        if (this.currentSampleSrc !== sampleSrc) {
        this.state.imgIndex = -1;
        this.state.docIndex = -1;
        this.state.imgsDocsIndex = -1;
        }
        console.log(this.state.docIndex);
        this.currentSampleSrc = this.props.imgSample;
        if ((sampleSrc !== undefined) && (sample_image_map.includes(sampleSrc))) {
            for (var index in sample_image_map){
                  if (sampleSrc == sample_image_map[index]){
                    if (this.state.targetImagesIndex != index)
                        this.setState({ targetImagesIndex: index });    
                    }
            }
        }
        if ((sampleSrc !== undefined) && (sample_doc_map.includes(sampleSrc))){
            for (var index in sample_doc_map){
                if (sampleSrc == sample_doc_map[index]){
                    if (this.state.targetDocIndex != index)          
                        this.setState({ targetDocIndex: index });
                    }
            }
        }
        const loadImgAsync = url => {
            return new Promise(resolve => {
                const img = new Image;
                img.src = url;
                img.onload=()=>{
                    resolve(img)
                }
            });
        }        
        async function loadImagesSequentially(urls) {
            const images = [];
            for (const url of urls) {
              const image = await loadImgAsync(url);  
              images.push(image);
            }
            return images;
          }
        
    
        if ((file !== undefined) && (this.props.fileTypeArray.every(item => item.includes('image')))) { 
            console.log("OriginView componentdidupdate:" + file)
            var length = file.length;
            const detectionR = this.props.imgDetection ? this.props.imgDetection : undefined;
            const selectedLabels = this.props.selectedLabels;
            const labelDisplayed = this.props.labelDisplayed;
            let nextY = 0;
            let base64promises = [];
            for (let num=0 ; num < length; num++){
            let filePromise = new Promise(resolve => {
                let imageDataReader = new FileReader()
                imageDataReader.readAsDataURL(file[num]);
                console.log(file[num].name)
                imageNames.push(file[num].name);
                imageDataReader.onload = () => resolve(imageDataReader.result);
            });
            base64promises.push(filePromise);
        } 
           Promise.all(base64promises).then(fileContents => {
            loadImagesSequentially(fileContents).then(images => {
                
               for(let m=0; m < fileContents.length; m++) {
                    const canvas = this.canvasRef.current;
                    console.log("image width:" + images[m].width + "\theight:" + images[m].height)
                    const ctx = canvas.getContext("2d");
                    ctx.lineWidth = 1.5;
                    ctx.font="16px Courier New";                
                    
                    const { imgOffsetX, imgOffsetY, offsetX, offsetY, ratio, dWidth, dHeight } = this.calculateOffsetAndRatio(images[m].width,images[m].height);
                    imageRatio.push(ratio);
                    imageOffsetX.push(imgOffsetX);
                    imageOffsetY.push(imgOffsetY);
                    if (m === 0) {
                    ctx.clearRect(0, 0, canvas_width, canvas_height*length);
                    }       
                    ctx.drawImage(images[m], offsetX, offsetY+nextY, dWidth, dHeight);
                    nextY+=canvas_height;
                    ctx.fillStyle = 'rgba(255, 255, 255, 0)';
                  
                    if (detectionR !== undefined) { 
                        this.drawBBox(detectionR,imageNames,imageRatio,imageOffsetX,imageOffsetY,ratio,canvas_height,imgOffsetX,imgOffsetY,selectedLabels,canvas,labelDisplayed);
            }
        }
    })      
})
}
        
       else if ((this.state.imgIndex !== -1) && file === undefined  && !sample_pdf_image_map.includes(this.props.imgSample)) {
            const detectionR = this.props.imgDetection ? this.props.imgDetection : undefined;
            const selectedLabels = this.props.selectedLabels;
            const labelDisplayed = this.props.labelDisplayed;
            console.log("OriginView componentdidupdate:" + sampleSrc)
            var targetImages = this.SampleImagesArr[this.state.targetImagesIndex];
            var imgindex = this.state.imgIndex;
            let imgSrc = targetImages[imgindex];
            let imgArray = [];
            let nextY = 0;
            
            if(!(imgSrc instanceof Array)){
                imgArray.push(imgSrc);
                let imgNameForOutLayer = "Sample.jpg"
                imageNames.push(imgNameForOutLayer)
            }
            else {
                imgArray = imgSrc
                for(let k =0; k< imgArray.length; k++){
                    let eachImageName = "sample" + k +".jpg"
                    imageNames.push(eachImageName)
                }  
            }

           loadImagesSequentially(imgArray).then(images => {
            const canvas = this.canvasRef.current;
            for (let m=0; m < imgArray.length; m++) {
              const ctx = canvas.getContext("2d");
              ctx.lineWidth = 1.5;
              ctx.font="16px Courier New";
              let canvas_width = 1080;
              let canvas_height = 700;
              const { imgOffsetX, imgOffsetY, offsetX, offsetY, ratio, dWidth, dHeight } = this.calculateOffsetAndRatio(images[m].width,images[m].height);
              imageRatio.push(ratio);
              imageOffsetX.push(imgOffsetX);
              imageOffsetY.push(imgOffsetY);   
            if (m === 0) {
            ctx.clearRect(0, 0, canvas_width, canvas_height*(images.length));
            }       
            ctx.drawImage(images[m], offsetX, offsetY+nextY, dWidth, dHeight);
            nextY+=canvas_height;
            ctx.fillStyle = 'rgba(255, 255, 255, 0)';
            
            if (detectionR !== undefined) {
                this.drawBBox(detectionR,imageNames,imageRatio,imageOffsetX,imageOffsetY,ratio,canvas_height,imgOffsetX,imgOffsetY,selectedLabels,canvas,labelDisplayed);
            }
         }   
      })
}

       else if ((this.state.imgIndex !== -1) && file === undefined  && sample_pdf_image_map.includes(this.props.imgSample)) {
        const detectionR = this.props.imgDetection ? this.props.imgDetection : undefined;
        const selectedLabels = this.props.selectedLabels;
        const labelDisplayed = this.props.labelDisplayed;
        console.log("OriginView componentdidupdate:" + sampleSrc)
        var targetImages = this.SampleImagesArr[this.state.targetImagesIndex];
        var imgindex = this.state.imgIndex;
        let fileSrc = targetImages[imgindex];
        let imgArray = [];
        let nextY = 0;     
            for(let k =0; k< fileSrc.length; k++){
                if(fileSrc[k].includes('pdf')){
                    let pdfName = "sample" + ".pdf"
                    imageNames.push(pdfName)
                }
                else {
                let eachImageName = "sample" + k +".jpg"
                imageNames.push(eachImageName)
                imgArray.push(fileSrc[k])
                }
            }

       loadImagesSequentially(imgArray).then(images => {
        const canvas = this.canvasRef.current;
        for (let m=0; m < imgArray.length; m++) {
          console.log("image width:" + imgArray[m].width + "\theight:" + imgArray[m].height)
          console.log(m)
          const ctx = canvas.getContext("2d");
          ctx.lineWidth = 1.5;
          ctx.font="16px Courier New";
          let canvas_width = 1080;
          let canvas_height = 700;
          const { imgOffsetX, imgOffsetY, offsetX, offsetY, ratio, dWidth, dHeight } = this.calculateOffsetAndRatio(images[m].width,images[m].height)
          imageRatio.push(ratio);
          imageOffsetX.push(imgOffsetX);
          imageOffsetY.push(imgOffsetY); 
        if (m === 0) {
        ctx.clearRect(0, 0, canvas_width, canvas_height*(images.length));
        }       
        ctx.drawImage(images[m], offsetX, offsetY+nextY, dWidth, dHeight);
        nextY+=canvas_height;
        ctx.fillStyle = 'rgba(255, 255, 255, 0)';
        if (detectionR !== undefined) {
            this.drawBBox(detectionR,imageNames,imageRatio,imageOffsetX,imageOffsetY,ratio,canvas_height,imgOffsetX,imgOffsetY,selectedLabels,canvas,labelDisplayed);
          }
        }
     })
 }
        else if ((file !== undefined) && (this.props.fileTypeArray.some(item => item.includes('pdf')))&&(this.props.fileTypeArray.some(item => item.includes('image')))){
            console.log("OriginView componentdidupdate:" + file)
            console.log(file)
            console.log(file.length)
            var image_files= []
            for (let i = 0; i < file.length; i++){
                const file_a = file[i];
                const fileType = file_a.type;
                console.log(fileType)
                if (fileType.includes('pdf')) {
                    this.PDFurl = URL.createObjectURL(file[i])
                    document.getElementsByName("PDFrender")[0].src=this.PDFurl;
                }
                if (fileType.includes('image')) {
                   image_files.push(file[i])
                }

            }
            console.log(image_files)
            var length = image_files.length;
            const detectionR = this.props.imgDetection ? this.props.imgDetection : undefined;
            const selectedLabels = this.props.selectedLabels;
            const labelDisplayed = this.props.labelDisplayed;
            let nextY = 0;
            let base64promises = [];
            let imageNames = [];
            let imageRatio = [];
            let imageOffsetX = [];
            let imageOffsetY = [];
            for (let num=0 ; num < length; num++){
            console.log(num);
            let filePromise = new Promise(resolve => {
                let imageDataReader = new FileReader()
                imageDataReader.readAsDataURL(image_files[num]);
                console.log(image_files[num].name)
                imageNames.push(image_files[num].name);
                imageDataReader.onload = () => resolve(imageDataReader.result);
            });
            base64promises.push(filePromise);
        }
       
           Promise.all(base64promises).then(fileContents => {
            loadImagesSequentially(fileContents).then(images => {
               for(let m=0; m < fileContents.length; m++) {
                    const canvas = this.canvasRef.current;
                    console.log("image width:" + images[m].width + "\theight:" + images[m].height)
                    const ctx = canvas.getContext("2d");
                    ctx.lineWidth = 1.5;
                    ctx.font="16px Courier New";                
                    let canvas_width = 1080;
                    let canvas_height = 700;
                    const { imgOffsetX, imgOffsetY, offsetX, offsetY, ratio, dWidth, dHeight } = this.calculateOffsetAndRatio(images[m].width,images[m].height);
                    imageRatio.push(ratio);
                    imageOffsetX.push(imgOffsetX);
                    imageOffsetY.push(imgOffsetY);
                    if (m === 0) {
                    ctx.clearRect(0, 0, canvas_width, canvas_height*length);
                    }       
                    ctx.drawImage(images[m], offsetX, offsetY+nextY, dWidth, dHeight);
                    nextY+=canvas_height;
                    ctx.fillStyle = 'rgba(255, 255, 255, 0)';

                    if (detectionR !== undefined) {
                        this.drawBBox(detectionR,imageNames,imageRatio,imageOffsetX,imageOffsetY,ratio,canvas_height,imgOffsetX,imgOffsetY,selectedLabels,canvas,labelDisplayed);
                    }//if detectR !== undefined       
                }
          })  
    })
}

       else  if ((file !== undefined) && (getType(fileName) == ".mp4")) {
            const url = URL.createObjectURL(file[0]);
            document.getElementById("video").src=url;
            const video = document.getElementById('video');
            video.onloadedmetadata = () => {
                this.processVideo();
            };
       }

       else  if ((file === undefined) && (this.props.videoURL !== "")) {
        const url = this.props.videoURL;
        console.log(url)
        document.getElementById("video").src=url;
        const video = document.getElementById('video');
        video.onloadedmetadata = () => {
            this.processVideo();
        };
    }
        
    else if ((file !== undefined) && (this.props.fileTypeArray.every(item => item.includes('pdf')))) { 
        this.PDFurl = URL.createObjectURL(file[0]);
        console.log(this.PDFurl)
        document.getElementsByName("PDFrender")[0].src=this.PDFurl;
        console.log(document.getElementsByName("PDFrender")[0] );
        }   
    }
    
    processVideo = () => {
        const video = document.getElementById('video');
        const canvas = this.canvasRef.current;
        this.boxData= [];
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        if (this.props.imgDetection !== undefined) {
            let detections = this.props.imgDetection;
          for (let j = 0, dlen = detections.length; j < dlen; j++) {
            let annotations = detections[j]['annotations'];
           annotations.forEach(annotation => {
            const { timestamp, label, box, isDefect } = annotation;
                if (!this.timestampList.includes(timestamp)) {
                    this.timestampList.push(timestamp);            
                }
                if (!this.labelTimestampDict[label]) {
                    this.labelTimestampDict[label] = [];
                }
                this.labelTimestampDict[label].push(timestamp);
                let existingTimestamp = this.boxData.find(box => box.timestamp === timestamp);
                if (existingTimestamp) {
                    existingTimestamp.annotations.push({
                    label: label,
                    box: box,
                    isDefect: isDefect
                  });
                } else {
                   this.boxData.push({
                   timestamp: timestamp,
                   annotations: [
                {
                   label: label,
                   box: box,
                   isDefect: isDefect
                 }
                ]
             });
           }
       })
              for (let label in this.labelTimestampDict) {
                this.labelTimestampDict[label] = [...new Set(this.labelTimestampDict[label])];
            }
              console.log(this.labelTimestampDict);
              console.log(this.boxData);
           }
        }
        else {
            this.timestampList = [];
            this.labelTimestampDict = {};
        }
        
        let dWidth, dHeight, offsetX, offsetY, snapshotHeight, snapshotWidth;
        let ratio = 1.0
        let imgOffsetX = 0;
        let imgOffsetY = 0;
        const canvasHeight = 700;
        const canvasWidth = 1080;
  
        video.addEventListener('loadedmetadata', ()=> {
            snapshotWidth = video.videoWidth;
            snapshotHeight = video.videoHeight;
            if (snapshotWidth >= canvasWidth || snapshotHeight >= canvasHeight) {
                console.log("Using scaled image size to calculate offsets");
                const ratioW = snapshotWidth / canvasWidth;
                const ratioH = snapshotHeight / canvasHeight;
                ratio = Math.max(ratioW, ratioH);
                console.log(ratio)
                dWidth = snapshotWidth / ratio;
                dHeight = snapshotHeight / ratio;
        
                if (ratioW < ratioH) {
                    imgOffsetX = (canvasWidth - dWidth) / 2;
                } else {
                    imgOffsetY = (canvasHeight - dHeight) / 2;
                }
            } else {
                if (snapshotWidth < canvasWidth) {
                    imgOffsetX = (canvasWidth - snapshotWidth) / 2;
                }
                if (snapshotHeight < canvasHeight) {
                    imgOffsetY = (canvasHeight - snapshotHeight) / 2;
                }
            }
                 offsetX = imgOffsetX;
                 offsetY = imgOffsetY;

            if (this.timestampList.length > 0){
              //   video.addEventListener('canplaythrough', () => {
              //  captureFrames(0, this.timestampList,this.props.imgDetection,this.props.selectedLabels);
              // }, { once: true });
           addMarkers(video,this.labelTimestampDict,this.props.selectedLabels,this.boxData,ratio,offsetX,offsetY,this.props.labelDisplayed);
           
         }       
                function isVideoPlaying(video) {
                   return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
               }
                function addMarkers(video,labelTimestampDict,selectedLabels,boxData,ratio,offsetX,offsetY,labelDisplayed) {
                    // clean existing markers
                    const markersContainer = document.getElementById('markersContainer');
                    markersContainer.innerHTML = '';
                    Object.keys(labelTimestampDict).forEach(label => {
                        const timestamps = labelTimestampDict[label];
                        if (selectedLabels.includes(label)) {
                            timestamps.forEach(timestamp => {
                              const parts = timestamp.split(':');
                              const secondsAndMilliseconds = parseFloat(parts[2].replace(',', '.'));
                              const percentage = (secondsAndMilliseconds / video.duration) * 100;
                              const marker = document.createElement('div');
                              marker.classList.add('marker');
                              marker.style.left = percentage + '%';

                            marker.addEventListener('click', (event) => {
                              event.stopPropagation(); // stop propagation
                              if (isVideoPlaying(video)) {
                                // Pause the video if it is playing
                                video.pause();
                                console.log('Video paused');
                            }
                              video.currentTime = secondsAndMilliseconds;
                              video.addEventListener('seeked', () => {
                              drawBoxesAtTimestamp(timestamp,selectedLabels,boxData,ratio,offsetX,offsetY,labelDisplayed); 
                              });
                          });    
                           markersContainer.appendChild(marker);
                        })
                      }
                    });
                    video.addEventListener('play', function() {
                    console.log('Video is playing, clearing canvas...');
                    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas when video starts playing
                    });
                }
                function drawBoxesAtTimestamp(timestamp,selectedLabels,boxData,ratio,offsetX,offsetY,labelDisplayed) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    console.log(labelDisplayed);
                    // Find the corresponding box data for the given timestamp
                    const boxInfo = boxData.find(data => data.timestamp === timestamp);
                    const rectX = 20;
                    const rectY = 20;
                    const rectWidth = 150;
                    const rectHeight = 50;
                    ctx.fillStyle = 'white';
                    ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
                    ctx.font = '20px Arial';
                    ctx.fillStyle = 'black';
                    ctx.fillText(timestamp, rectX + 10, rectY + 30);
                    if (boxInfo) {
                        boxInfo.annotations.forEach(annotation => {
                            //console.log(annotation);
                            let [leftX, leftY, rectWidth, rectHeight] = annotation['box'].box;      
                              leftX = leftX / ratio + offsetX;
                              leftY = leftY / ratio + offsetY;
                              rectWidth = rectWidth / ratio;
                              rectHeight = rectHeight / ratio;
                            if (selectedLabels.includes(annotation.label)) {
                              if (annotation.isDefect) {
                                ctx.strokeStyle = RED;
                              } else {
                                ctx.strokeStyle = GREEN;
                              }
                            ctx.lineWidth = 1.5;
                            ctx.strokeRect(leftX, leftY, rectWidth, rectHeight); 
                            if(labelDisplayed === true){
                                let detMsg = annotation.label+ ':' + String(annotation['box'].score);
                                ctx.strokeText(detMsg, leftX, (leftY-5)>0?(leftY-5):0);
                            }
                          }
                        });
                    }
                }
            });
    };

    drawBBox(detectionR,imageNames,imageRatio,imageOffsetX,imageOffsetY,ratio,canvas_height,imgOffsetX,imgOffsetY,selectedLabels,canvas,labelDisplayed){
        const ctx = canvas.getContext("2d");
        let detections = detectionR;
        for (let j = 0, dlen = detections.length; j < dlen; j++) {
            let annotations = detections[j]['annotations'];
            let annotation_length = annotations.length;
            if (annotation_length !== 0){
                for (let p = 0, alen = annotations.length; p < alen; p++) {
                    let imageName = annotations[p]['name'];
                    let index = imageNames.indexOf(imageName);
                    let boxInfo = annotations[p]['box'];
                    let segmentationInfo = annotations[p]['segmentation'];                                    
                    if (boxInfo !== undefined) {
                         if ((annotations[p]['label'] !== 'non_front_view_antenna') &&(index !== -1)){
                            let leftX = boxInfo['box'][0]  / imageRatio[index] + imageOffsetX[index], leftY = boxInfo['box'][1]  / imageRatio[index] + imageOffsetY[index] + index*canvas_height; 
                            let rectWidth = boxInfo['box'][2]  / imageRatio[index], rectHeight = boxInfo['box'][3]  / imageRatio[index];
                            if (selectedLabels.includes(annotations[p]['label'])){
                                if (annotations[p]['isDefect']===false) {
                                    ctx.strokeStyle = GREEN
                                }else 
                                   {
                                    ctx.strokeStyle = RED
                                    }
                                    ctx.strokeRect(leftX, leftY, rectWidth, rectHeight);
                                if(labelDisplayed === true){
                                    let detMsg = annotations[p]['label'] + ':' + String(boxInfo['score']);
                                    ctx.strokeText(detMsg, leftX, (leftY-5)>0?(leftY-5):0);
                                    }
                                 }                    
                            }
                        if ((annotations[p]['label'] !== 'non_front_view_antenna') &&(index === -1)){
                            let leftX = boxInfo['box'][0]  / ratio + imgOffsetX, leftY = boxInfo['box'][1]  / ratio + imgOffsetY;
                            let rectWidth = boxInfo['box'][2]  / ratio, rectHeight = boxInfo['box'][3]  / ratio;
                            if (selectedLabels.includes(annotations[p]['label'])){
                                if (annotations[p]['isDefect']===false) {
                                    ctx.strokeStyle = GREEN
                                }else 
                                   {
                                    ctx.strokeStyle = RED
                                    }
                                    ctx.strokeRect(leftX, leftY, rectWidth, rectHeight);
                                if(labelDisplayed === true){
                                    let detMsg = annotations[p]['label'] + ':' + String(boxInfo['score']);
                                    ctx.strokeText(detMsg, leftX, (leftY-5)>0?(leftY-5):0);
                                    }
                                 }  
                               }
                              
                              if (segmentationInfo !== undefined)  {
                                let polygonInfo = segmentationInfo['polygon']
                                ctx.beginPath(); 
                                ctx.moveTo(polygonInfo[0]/ratio +imgOffsetX, polygonInfo[1]/ratio +imgOffsetY)
                                ctx.strokeStyle= "blue";
                               for (var i = 2, plen = polygonInfo.length; i < plen; i += 2){
                                    ctx.lineTo(polygonInfo[i]/ratio + imgOffsetX, polygonInfo[i+1]/ratio + imgOffsetY);
                                }
                                ctx.closePath();
                                ctx.fill();
                                ctx.stroke();
                               }
                           }     
                    }
                }    
            }
    }
    calculateOffsetAndRatio(width,height){
        let dWidth = width, dHeight = height;
        let ratio = 1.0
        let imgOffsetX = 0;
        let imgOffsetY = 0;
        let canvas_width = 1080;
        let canvas_height = 700;
        if (width >= canvas_width || height >= canvas_height) {
                console.log("Using scaled image size to calculate offsets");
                let ratioW = width * 1.0 / canvas_width;
                let ratioH = height * 1.0 / canvas_height;
                ratio = ratioW < ratioH ? ratioH : ratioW;           
                dWidth = width / ratio;
                dHeight = height / ratio;
                if (ratioW < ratioH) {
                   imgOffsetX = (canvas_width - dWidth) / 2;}                         
                else {
                    imgOffsetY = (canvas_height - dHeight) / 2;}   
                    } 
        else {
                console.log("Using original image size to calculate offsets");
                if (width < canvas_width) {
                    imgOffsetX = (canvas_width - width) / 2;
                    }
                if (height < canvas_height) {
                    imgOffsetY = (canvas_height - height) / 2;
                    }
                }
                //let offsetX = (canvas_width - dWidth) / 2;
                //let offsetY = (canvas_height - dHeight) / 2;
                let offsetX = imgOffsetX;
                let offsetY = imgOffsetY;
            return {imgOffsetX,imgOffsetY,offsetX,offsetY,ratio,dWidth,dHeight};        
        } 
    handleImgIndex = (imgIndex) => {
        this.setState({ imgIndex: imgIndex });
      }

    handleDocIndex = (docIndex) => {
        this.setState({ docIndex: docIndex });
      }
  
    selectGroupForLM(GroupFile,groupIndex,handleImgIndex,updateBase64){
        console.log(GroupFile);
        console.log(groupIndex)
        var imgPromises = [];
        var sampleDetails = [];
        return ()=>{
            for (let i = 0; i < GroupFile.length; i++) {
                console.log(GroupFile[i]);
                if (GroupFile[i].includes('pdf')) {
                    this.PDFurl = GroupFile[i];
                    let detail = {};
                    detail.name = "sample" + ".pdf";
                    // Use an async IIFE to handle the asynchronous operations
                    (async () => {
                        const pdfUrl = this.PDFurl;
                        try {
                            const response = await fetch(pdfUrl);
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
                            const blob = await response.blob();
            
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const base64StringPDF = reader.result.split(',')[1];
                                detail.imageBase64 = base64StringPDF;
                                sampleDetails.push(detail);
                            };
                            reader.readAsDataURL(blob);
                        } catch (error) {
                            console.error('Error fetching or converting the PDF:', error);
                        }
                    })();
                }
                    else {
                        let imgPromise = new Promise(resolve => {
                            const img = new Image;
                            img.src = GroupFile[i];
                            console.log(img.src)
                            img.onload=()=>{
                                resolve(img);           
                            };
                        })
                            imgPromises.push(imgPromise) 
                        }
                    }
                    console.log(imgPromises)
                    Promise.all(imgPromises).then(imgs => {
                        for (let m = 0; m < imgs.length; m++) {
                           let dWidth = imgs[m].width, dHeight = imgs[m].height;
                           var canvas = document.createElement("canvas")
                           canvas.width = dWidth;
                           canvas.height =  dHeight;
                           canvas.getContext("2d").drawImage(imgs[m],0,0,dWidth,dHeight);
                           var ext = imgs[m].src.substring(imgs[m].src.lastIndexOf(".")+1).toLowerCase();
                           if (ext == "jpg")
                              ext = "jpeg";
                           var dataBase64withPrefix = canvas.toDataURL("image/"+ext);
                           var dataBase64 = dataBase64withPrefix.substr(dataBase64withPrefix.lastIndexOf(",") + 1);
                        var detail = {}
                            detail.name = "sample"+ m + ".jpg"
                            detail.imageBase64 = dataBase64
                            sampleDetails.push(detail)
                        }
                    }) 
               updateBase64(sampleDetails)
               handleImgIndex(groupIndex);
            }
    }
    selectGroup(imgGroupFile,groupIndex,handleImgIndex,updateBase64){
        console.log(imgGroupFile)
        var imgPromises = [];
        var samleDetails = [];
        return ()=>{           
             for(let i=0; i< imgGroupFile.length; i++){
                console.log(i)
                let imgPromise = new Promise(resolve => {
                const img = new Image;
                img.src = imgGroupFile[i];
                console.log(img.src)
                img.onload=()=>{
                    resolve(img); 
                };
            })
                imgPromises.push(imgPromise) 
        }
                Promise.all(imgPromises).then(imgs => {
                    for (let m = 0; m < imgs.length; m++) {
                       let dWidth = imgs[m].width, dHeight = imgs[m].height;
                       var canvas = document.createElement("canvas")
                       canvas.width = dWidth;
                       canvas.height =  dHeight;
                       canvas.getContext("2d").drawImage(imgs[m],0,0,dWidth,dHeight);
                       var ext = imgs[m].src.substring(imgs[m].src.lastIndexOf(".")+1).toLowerCase();
                       if (ext == "jpg")
                          ext = "jpeg";
                       var dataBase64withPrefix = canvas.toDataURL("image/"+ext);
                       var dataBase64 = dataBase64withPrefix.substr(dataBase64withPrefix.lastIndexOf(",") + 1);
                    var detail = {}
                        detail.name = "sample"+ m + ".jpg"
                        detail.imageBase64 = dataBase64
                        samleDetails.push(detail)
                    }
                })

        console.log(samleDetails)
        updateBase64(samleDetails)
        handleImgIndex(groupIndex);
    }
}
    select(imgfile,imgIndex,handleImgIndex,updateBase64) {
        return ()=>{
            var img = new Image();
            img.src = imgfile;
            var dataBase64 = '';
            img.onload = function(){
                let dWidth = img.width, dHeight = img.height;
                var canvas = document.createElement("canvas")
                canvas.width = dWidth;
                canvas.height =  dHeight;
                canvas.getContext("2d").drawImage(img,0,0,dWidth,dHeight);
                var ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();
                if (ext == "jpg")
                    ext = "jpeg";
                var dataBase64 = canvas.toDataURL("image/"+ext);
                var base64 = dataBase64.substr(dataBase64.lastIndexOf(",") + 1);
                console.log(base64)
                updateBase64(base64)
                handleImgIndex(imgIndex );
                console.log("this.imgIndex was updated:"+imgIndex);
                console.log("database64:done");
            }
            console.log("inference click")
        }
    };
    selectDoc(docfile,docIndex,handleDocIndex,updateURL){
        return()=>{
            let docfileNameWithNumber = docfile.substr(docfile.lastIndexOf('/')+1);
            let docfileName = docfileNameWithNumber.substr(0,docfileNameWithNumber.indexOf('.'));
            console.log(docfileName);
            var docURL = doc_URL_mapping[docfileName];
            updateURL(docURL);
            handleDocIndex(docIndex);        
        }
    }
 
    showAll(onCleanBase64,onCleanDetection) {
        return ()=> {
        this.setState({ imgIndex: -1 });
        this.state.imgDetection = undefined;
        onCleanBase64();
        onCleanDetection();
        }
    };

    showAllDoc(onCleanURL) {
        return ()=> {
        this.setState({ docIndex: -1 });
        onCleanURL();
        }
    };
    render() {
        console.log("render:..."+this.state.imgIndex);
        console.log("render:..."+this.state.docIndex);
        var showSelectedContent
        if ((this.props.imgSrc !== undefined) && (this.props.fileTypeArray.every(item => item.includes('image/')))){
            showSelectedContent = <canvas ref={this.canvasRef} width={this.width} height={(this.props.imgNum)*700}/>}

        else if (((this.props.imgSrc === undefined)&& (this.props.videoURL !== "")) || ((this.props.imgSrc !== undefined)&& (this.props.fileTypeArray.every(item => item.includes('video/mp4'))))){
                showSelectedContent = <div className="video-container">
                <video object id="MediaPlayer" height={this.height} width={this.width} id="video" type="video/mp4" controls="controls" preload="auto"></video> 
                <canvas id="overlayCanvas" ref={this.canvasRef} width={this.width} height={this.height}></canvas>
                {(this.props.imgDetection) && (
                <div class="progress-container" id="progressContainer" onclick="seek(event)">
                <div class="progress-bar" id="progressBar"></div>
                <div id="markersContainer" class="markers-container"></div>
               </div> 
               )} 
                </div>
                }

        else if ((this.props.imgSrc !== undefined)&& (this.props.fileTypeArray.every(item => item.includes('pdf')))){
            showSelectedContent = <div className="container my-3">
            <iframe name="PDFrender"  width="900" height="1200" type="application/pdf" src={this.PDFurl} ></iframe>
            </div>
                }
        else if ((this.props.imgSrc !== undefined)&& (this.props.fileTypeArray.some(item => item.includes('pdf')))&&(this.props.fileTypeArray.some(item => item.includes('image')))){
            showSelectedContent =  <div className="preview-container">
            {
              <div className="container my-3">
                <canvas ref={this.canvasRef} width={this.width} height={((this.props.imgNum)-1)*700}/>
              </div>
            }
            {
              <div className="container my-3">
                <iframe name="PDFrender"  width="900" height="1200" type="application/pdf" src={this.PDFurl} ></iframe>
              </div>
        }
          </div>
        }
        var showSampleContent
        if ((this.props.imgSample !== undefined) && (sample_image_map.includes(this.props.imgSample) && (!sample_pdf_image_map.includes(this.props.imgSample))))
            {   
                console.log(this.props.imgSample)
                var targetImages = this.SampleImagesArr[this.state.targetImagesIndex];
                var showImages;
                if((this.props.approved === false) &&(this.props.rejected === false)){
                    showImages = targetImages.map((item,index) => ({ item, index }))
                }
                if((this.props.approved === false) &&(this.props.rejected === true)){
                    showImages = targetImages.map((item,index) => ({ item, index })).filter(({ item }) =>{
                        if(!(item instanceof Array) && (item.indexOf('approved') !== -1)){
                            return item 
                        }
                        if((item instanceof Array) && (item[0].indexOf('approved') !== -1)){
                            return item 
                        }
                })
            }
                if((this.props.approved === true) &&(this.props.rejected === false)){
                    showImages = targetImages.map((item,index) => ({ item, index })).filter(({ item }) =>{
                        if(!(item instanceof Array) && (item.indexOf('rejected') !== -1)){
                            return item 
                        }
                        if((item instanceof Array) && (item[0].indexOf('rejected') !== -1)){
                            return item 
                        }
                })
                }
                if (showImages !== undefined) {
                showSampleContent = showImages.map((item, index) =>
                {
                    console.log("this.state.imgINdex:"+this.state.imgIndex)
                    if( this.state.imgIndex ===-1 ) {
                        console.log("display..."+index.toString())
                        if(!(item['item'] instanceof Array)){
                        return <div class="div-inline">
                            <img src={item['item']} height="250" width="400" ></img>
                            <p>
                        <button className="btn btn-success"  onClick={this.select(item['item'],item['index'],this.handleImgIndex,this.props.onimgBase64)} >
                        Select
                        </button></p>
                        </div>
                        }
                        else if(item['item'] instanceof Array){
                               return <div class="div-inline">
                               <img src={item['item'][0]} height="250" width="400"></img>
                               <p>
                                <button className='btn btn-success' onClick={this.selectGroup(item['item'],item['index'],this.handleImgIndex,this.props.onimgBase64)} >
                                SelectGroup
                                </button></p>
                            </div>         
                        }
                    }
                    else
                        if( this.state.imgIndex == item['index'] ) {
                            console.log("display..."+index.toString())
                            if(!(item['item'] instanceof Array)){
                            return <div className="container my-3">
                                <canvas ref={this.canvasRef} width={this.width} height={this.height} />
                            <p>This Image was selected!</p>
                            <p><button className="btn btn-success"  onClick={this.showAll(this.props.onCleanBase64,this.props.onCleanDetection)} >
                            show all
                            </button>
                            </p>
                            </div>
                        }
                        
                        if(item['item'] instanceof Array){
                            return <div className="container my-3">
                                <canvas ref={this.canvasRef} width={this.width} height={(item['item'].length)*700} />
                            <p>This Group was selected!</p>
                            <p><button className="btn btn-success"  onClick={this.showAll(this.props.onCleanBase64,this.props.onCleanDetection)} >
                            show all
                            </button>
                            </p>
                            </div>
                        }
                    }
                })
            }
        }
        else if ((this.props.imgSample !== undefined) && (sample_pdf_image_map.includes(this.props.imgSample))) {
            var targetFiles = this.SampleImagesArr[this.state.targetImagesIndex]; 
            showSampleContent = targetFiles.map((item, index) =>
            {
            if( this.state.imgIndex ===-1 ) {  
              console.log(item)
              return <div class="div-inline">
              <img src={item[0]} height="250" width="400"></img>
              <p>
               <button className='btn btn-success' onClick={this.selectGroupForLM(item,index,this.handleImgIndex,this.props.onimgBase64)} >
               SelectGroup
               </button></p>
           </div>
              
            }
            else if( this.state.imgIndex == index ) {
                return <div className="preview-container">
                {
                  <div className="container my-3">
                    <canvas ref={this.canvasRef} width={this.width} height={(item.length-1)*700} />
                  </div>
                }
                {
                  <div className="container my-3">
                    <iframe name="PDFrender"  width="900" height="1200" type="application/pdf" src={this.PDFurl} ></iframe>
                  </div>
            }
             <p>This Group was selected!</p>
            <p><button className="btn btn-success"  onClick={this.showAll(this.props.onCleanBase64,this.props.onCleanDetection)} >
            show all
            </button>
            </p>
              </div>
            }     
        })
        }
        else if ((this.props.imgSample !== undefined) && (sample_doc_map.includes(this.props.imgSample))) {
            var targetDocs = this.SampleDocsArr[this.state.targetDocsIndex];
            console.log(targetDocs)
            showSampleContent = targetDocs.map((item, index) =>
                {
                    if( this.state.docIndex ===-1 ) {
                        console.log("display..."+index.toString())
                        return <div class="div-inline">
                            <iframe src={item} width="300" height="400" type="application/pdf"></iframe>
                            <p>
                        <button className="btn btn-success"  onClick={this.selectDoc(item,index,this.handleDocIndex,this.props.ondocURL)} >
                        Select
                        </button></p>
                        </div>                       
                    }

                    else
                        if( this.state.docIndex == index ) {
                            console.log("display..."+index.toString());   
                            return <div class="div-inline">
                                <iframe src={item} width="900" height="1200" type="application/pdf" ></iframe>
                                 <p>This doc was selected!</p>
                           <p><button className="btn btn-success"  onClick={this.showAllDoc(this.props.onCleanURL)} >
                            show all
                            </button>
                            </p>
                            </div>                                  
                    }

                })
            }  
        else
            showSampleContent = <a/>
        return (
            <div className="container border ">
                {showSelectedContent}
                {showSampleContent}
            </div>
        )
    }
}
