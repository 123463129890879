/* eslint-disable no-loop-func */
import React, { useState,useEffect} from "react"
import uploadService from '../services/FileUploadService'
import Select, { components } from 'react-select';
import '../FileUpLoader.css';


const categories = [ { value: 'PPE', label: 'EHS', UC: 'PPE_Helmet_Vest_Shoe_Det', Sample: 'PPE_Helmet_Vest_Shoe_Sample'},
                     { value: 'Antenna', label: 'QAS', UC: 'Antenna_Coverage_Area_Det', Sample: 'Antenna_Coverage_Area_Sample'},
                     { value: 'Image quality', label: 'Common', UC: 'Blurred_Image_Det', Sample: 'Blurred_Image_Sample'}
                     ]
const options = [
    { value: 'Generic_OCR_Sample', label: 'Generic_OCR_Det', category: 'QAS',link: 'General and OCR'},
    { value: 'Cable_Run_Checking_Sample',  label: 'Cable_Run_Checking_Det' ,link:'General and OCR'},
    { value: 'Meter_Reading_Sample', label: 'Meter_Reading_Det', category: 'QAS',link: 'General and OCR'},
    { value: 'Manufacture_Label_Reading_Sample', label: 'Manufacture_Label_Reading_Det', link: 'General and OCR'},
    { value: 'Antenna_Coverage_Area_Sample',  label: 'Antenna_Coverage_Area_Det' ,link:'Antenna'},
    { value: 'LLM_Antenna_Azimuth_Validation_Sample',  label: 'LLM_Antenna_Azimuth_Validation_Det' ,link:'LM-PoC'},
    { value: 'LLM_Site_Access_Detection_Sample',  label: 'LLM_Site_Access_Detection_Det' ,link:'LM-PoC'},
    { value: 'LLM_Spare_1_Sample',  label: 'LLM_Spare_1_Det' ,link:'LM-PoC'},
    { value: 'Antenna_Front_Facing_Sample',  label: 'Antenna_Front_Facing_Det' ,link:'Antenna'},
    { value: 'Antenna_Grounding_Grouping_2_Sample',  label: 'Antenna_Grounding_Grouping_2_Det' ,link:'Antenna'},
    { value: 'Antenna_Height_Reading_Sample', label: 'Antenna_Height_Reading_Det',link: 'Antenna'},
    { value: 'Antenna_Label_Reading_Sample', label: 'Antenna_Label_Reading_Det',link: 'Antenna'},
    { value: 'QR_And_Barcode_Available_Sample', label: 'QR_And_Barcode_Available_Det',link:'General and OCR'},
    { value: 'QR_And_Barcode_Reader_Sample', label: 'QR_And_Barcode_Reader_Det',link:'General and OCR'},
    { value: 'Battery_Defect_Sample', label: 'Battery_Defect_Det',link:'General and OCR'},
    { value: 'Baseband_Mounting_Sample',   label: 'Baseband_Mounting_Det',link:'Baseband' },
    { value: 'Baseband_Mounting_Grouping_3_Sample',   label: 'Baseband_Mounting_Grouping_3_Det',link:'Baseband' },
    { value: 'Baseband_Mounting_And_Unused_Port_Checking_Grouping_3_Sample',   label: 'Baseband_Mounting_And_Unused_Port_Checking_Grouping_3_Det',link:'Baseband' },
    { value: 'Baseband_Grounding_Sample',  label: 'Baseband_Grounding_Det',link:'Baseband'},
    { value: 'Busbar_Front_Sample', label: 'Busbar_Front_Det', link: 'General and OCR'},
    { value: 'Grounding_Busbar_Sample',  label: 'Grounding_Busbar_Det',link:'General and OCR'},
    { value: 'Baseband_Grounding_Grouping_3_Sample',   label: 'Baseband_Grounding_Grouping_3_Det',link:'Baseband' },
    { value: 'Baseband_Unused_Port_Sealing_Sample',  label: 'Baseband_Unused_Port_Sealing_Det',link:'Baseband'},
    { value: 'Baseband_Mounting_Ext_Sample', label: 'Baseband_Mounting_Ext_Det', link: 'Baseband'},
    { value: 'Baseband_Grounding_Ext_Sample', label: 'Baseband_Grounding_Ext_Det', link: 'Baseband'},
    { value: 'Baseband_GPS_Cable_Ext_Sample', label: 'Baseband_GPS_Cable_Ext_Det', link: 'Baseband'},
    { value: 'Cabinet_Installed_Equipment_Defect_Sample',  label: 'Cabinet_Installed_Equipment_Defect_Det' ,link:'Cabinet'},
    { value: 'Cabinet_Installed_Equipment_Recognition_Sample',  label: 'Cabinet_Installed_Equipment_Recognition_Det' ,link:'Cabinet'},
    { value: 'Cabinet_Leveling_Grouping_3_Sample',  label: 'Cabinet_Leveling_Grouping_3_Det' ,link:'Cabinet'},
    { value: 'Cabling_Inside_Cabinet_Sample',  label: 'Cabling_Inside_Cabinet_Det' ,link:'Cabinet'},
    { value: 'Baseframe_Cabinet_Mounting_Grouping_6_Sample',  label: 'Baseframe_Cabinet_Mounting_Grouping_6_Det' ,link:'Cabinet'},
    { value: 'Battery_Presence_Sample', label: 'Battery_Presence_Det',link:'Cabinet'},
    { value: 'Circuit_Breaker_Connection_Sample',  label: 'Circuit_Breaker_Connection_Det' ,link:'Cabinet'},
    { value: 'Loose_Hardware_Sample', label:'Loose_Hardware_Det', link: 'General and OCR'},
    { value: 'Cable_Clamp_Checking_Sample', label:'Cable_Clamp_Checking_Det', link: 'General and OCR'},
    { value: 'Cable_Inlet_Presence_Sample', label:'Cable_Inlet_Presence_Det', link: 'General and OCR'},
    { value: 'Cable_Tie_Checking_Sample', label:'Cable_Tie_Checking_Det', link:'General and OCR'}, 
    { value: 'Earthing_Kit_And_Grounding_Grouping_5_Sample', label:'Earthing_Kit_And_Grounding_Grouping_5_Det', link: 'MW-TRM'},
    { value: 'KWH_Meter_Presence_Sample', label:'KWH_Meter_Presence_Det', link: 'General and OCR'},
    { value: 'Level_Check_Sample', label:'Level_Check_Det', link: 'General and OCR'},
    { value: 'DDF_Detection_Sample', label: 'DDF_Detection_Det',link:'Cabinet'},
    { value: 'Door_Status_Checking_Sample', label: 'Door_Status_Checking_Det',link:'Cabinet'}, 
    { value: 'Foundation_Of_Cabinet_Sample', label: 'Foundation_Of_Cabinet_Det',link:'Cabinet'}, 
    { value: 'Indoor_TRM_Mounting_And_Unused_Ports_Checking_Grouping_3_Sample',  label: 'Indoor_TRM_Mounting_And_Unused_Ports_Checking_Grouping_3_Det', link:'MW-TRM'},
    { value: 'MW_RAU_Grounding_Grouping_N_Sample',  label: 'MW_RAU_Grounding_Grouping_N_Det', link:'MW-TRM'},
    { value: 'MCB_Detection_Sample', label: 'MCB_Detection_Det',link:'Cabinet'},  
    { value: 'Antenna_Mounting_Sample',     label: 'Antenna_Mounting_Det' ,link: 'Antenna'},
    { value: 'Antenna_Mounting_Grouping_3_Sample',     label: 'Antenna_Mounting_Grouping_3_Det' ,link: 'Antenna'},
    { value: 'Antenna_Mounting_Torque_Mark_Checking_Sample',     label: 'Antenna_Mounting_Torque_Mark_Checking_Det' ,link: 'Antenna'},
    { value: 'Blurred_Image_Sample', label: 'Blurred_Image_Det', link:'Image quality'},
    { value: 'Radio_Mounting_Sample',     label: 'Radio_Mounting_Det' ,link: 'Radio'},
    { value: 'Radio_Mounting_Grouping_3_Sample',     label: 'Radio_Mounting_Grouping_3_Det' ,link: 'Radio'},
    { value: 'GPS_Cable_Routing_Sample', label: 'GPS_Cable_Routing_Det', link: 'GPS'},
    { value: 'GPS_Antenna_Grounding_Sample', label: 'GPS_Antenna_Grounding_Det', link:'GPS'},
    { value: 'GPS_Main_Grounding_Sample', label: 'GPS_Main_Grounding_Det', link:'GPS'},
    { value: 'GPS_Grounding_Grouping_2_Sample', label: 'GPS_Grounding_Grouping_2_Det', link:'GPS'},
    { value: 'GPS_Mounting_Sample', label:'GPS_Mounting_Det', link:'GPS'},
    { value: 'GPS_Weatherproofing_Sample', label:'GPS_Weatherproofing_Det', link:'GPS'},
    { value: 'Nameplate_Data_Recognition_Sample', label: 'Nameplate_Data_Recognition_Det', link:'General and OCR'},
    { value: 'RBS_Main_Grounding_Grouping_2_Sample', label: 'RBS_Main_Grounding_Grouping_2_Det', link:'Cabinet'},
    { value: 'TRM_Mounting_Grouping_3_Sample',  label: 'TRM_Mounting_Grouping_3_Det', link:'MW-TRM'},
    { value: 'Roxtec_Defect_Sample',  label: 'Roxtec_Defect_Det', link:'Cabinet'},
    { value: 'Passive_Tagging_Sample',  label: 'Passive_Tagging_Det', link:'General and OCR'},
    { value: 'PPE_Helmet_Vest_Shoe_Sample',   label: 'PPE_Helmet_Vest_Shoe_Det' ,link: 'PPE'},
    { value: 'PPE_Helmet_Shoes_Sample', label: 'PPE_Helmet_Shoes_Det', link: 'PPE'},
    { value: 'PPE_Helmet_Harness_Gloves_Shoes_Sample',   label: 'PPE_Helmet_Harness_Gloves_Shoes_Det' ,link: 'PPE'},
    { value: 'PPE_Safety_Checking_Sample',   label: 'PPE_Safety_Checking_Det' ,link: 'PPE'},    
    { value: 'PPE_Rigger_Sample', label: 'PPE_Rigger_Det' ,link: 'PPE'},
    { value: 'PPE_Rigger_Video_Sample', label: 'PPE_Rigger_Video_Det' ,link: 'Video File Analysis'},
    { value: 'PPE_Rigger_And_Safety_Checking_Video_Sample', label: 'PPE_Rigger_And_Safety_Checking_Video_Det' ,link: 'Video File Analysis'},
    { value: 'PPE_Climbing_Checking_Video_Sample', label: 'PPE_Climbing_Checking_Video_Det' ,link: 'Video File Analysis'},  
    { value: 'PPE_Rigger_Without_Vest_Sample', label: 'PPE_Rigger_Without_Vest_Det' ,link: 'PPE'},
    { value: 'PPE_Electrical_Sample', label: 'PPE_Electrical_Det', link: 'PPE'},
    { value: 'Caution_Tape_Checking_Sample', label: 'Caution_Tape_Checking_Det', link: 'Tools, kits and OCR'},
    { value: 'Crane_Drop_Zone_Sample', label: 'Crane_Drop_Zone_Det', link: 'Tools, kits and OCR'},
    { value: 'Crane_Position_Sample', label: 'Crane_Position_Det', link: 'Tools, kits and OCR'},
    { value: 'Emergency_Plan_Banner_Sample', label: 'Emergency_Plan_Banner_Det', link: 'Tools, kits and OCR'},
    { value: 'Hand_Held_Tools_Checking_Sample', label: 'Hand_Held_Tools_Checking_Det', link: 'Tools, kits and OCR'},
    { value: 'First_Aid_Box_Sample', label: 'First_Aid_Box_Det', link:'Tools, kits and OCR'},
    { value: 'Fire_Extinguisher_Sample', label: 'Fire_Extinguisher_Det', link:'Tools, kits and OCR'},
    { value: 'Rigging_Kit_Checking_Sample', label: 'Rigging_Kit_Checking_Det', link:'Tools, kits and OCR'},
    { value: 'Rigging_Kit_1_Pulley_Sample', label: 'Rigging_Kit_1_Pulley_Det', link:'Tools, kits and OCR'},
    { value: 'Rigging_Kit_2_Pulley_Sample', label: 'Rigging_Kit_2_Pulley_Det', link:'Tools, kits and OCR'},
    { value: 'Rigging_Kit_3_Pulley_Sample', label: 'Rigging_Kit_3_Pulley_Det', link:'Tools, kits and OCR'},
    { value: 'Site_Access_Detection_Sample', label: 'Site_Access_Detection_Det', link:'General and OCR'},
    { value: 'Sticker_Reading_Sample', label: 'Sticker_Reading_Det', link:'General and OCR'},
    { value: 'SRS_Screenshot_Grouping_4_Sample', label: 'SRS_Screenshot_Grouping_4_Det', link:'General and OCR'},
    { value: 'Unistrut_End_Cap_Sample', label: 'Unistrut_End_Cap_Det', link: 'General and OCR'},
    { value: 'Vehicle_Airbags_Sample', label: 'Vehicle_Airbags_Det', link: 'Vehicle'},
    { value: 'Vehicle_Light_Windshield_Licenseplate_Sample', label: 'Vehicle_Light_Windshield_Licenseplate_Det' ,link: 'Vehicle'},
    { value: 'Vehicle_Odometer_Sample', label: 'Vehicle_Odometer_Det', link: 'Vehicle'},
    { value: 'Vehicle_Seatbelts_Sample', label: 'Vehicle_Seatbelts_Det', link: 'Vehicle'},
    { value: 'Vehicle_Tyre_Sample', label: 'Vehicle_Tyre_Det', link: 'Vehicle'},
    { value: 'Vin_Extraction_Sample', label: 'Vin_Extraction_Det', link: 'Vehicle'},
    { value: 'Safe_Work_Info_Verify_Sample', label: 'Safe_Work_Info_Verify_Det', link: 'Tools, kits and OCR'},
    { value: 'Thermometer_Reading_Sample', label: 'Thermometer_Reading_Det',link:'Tools, kits and OCR'},
    { value: 'Tilt_Meter_Presence_Sample', label: 'Tilt_Meter_Presence_Det',link:'General and OCR'},
    { value: '', label: 'Worker_Identification_Det',link:'Tools, kits and OCR'},
    { value: 'Antenna_Weatherproofing_Sample',  label: 'Antenna_Weatherproofing_Det',link:'Antenna' },
    { value: 'Antenna_Weatherproofing_And_Unused_Port_Sealing_Sample',  label: 'Antenna_Weatherproofing_And_Unused_Port_Sealing_Det',link:'Antenna' },
    { value: 'Radio_Weatherproofing_Sample',  label: 'Radio_Weatherproofing_Det',link:'Radio' },
    { value: 'Radio_Weatherproofing_And_Unused_Port_Sealing_Sample',  label: 'Radio_Weatherproofing_And_Unused_Port_Sealing_Det',link:'Radio' },
    { value: 'Radio_Grounding_Sample',  label: 'Radio_Grounding_Det',link:'Radio' },
    { value: 'Radio_Grounding_Grouping_2_Sample',  label: 'Radio_Grounding_Grouping_2_Det',link:'Radio' },
    { value: 'Color_Sticker_Sample',  label: 'Color_Sticker_Det',link:'Radio' },
    { value: 'Jumper_Color_Code_Sample', label: 'Jumper_Color_Code_Det', link: 'Radio'},
    { value: 'Site_Data_Extraction_From_Image_Sample', label:'Site_Data_Extraction_From_Image_Det', link:'General and OCR'}, 
    { value: 'Site_Entrance_Checking_Sample', label:'Site_Entrance_Checking_Det', link:'General and OCR'}, 
    { value: 'Vertical_Power_Cable_Color_Code_Sample', label:'Vertical_Power_Cable_Color_Code_Det', link:'General and OCR'}, 
    { value: 'Tower_Installed_Equipment_Defect_Sample',   label: 'Tower_Installed_Equipment_Defect_Det', link:'Tower'},
    { value: 'Tower_Installed_Equipment_Recognition_Sample',   label: 'Tower_Installed_Equipment_Recognition_Det', link:'Tower'},
    { value: 'Tower_Type_Recognition_Sample',   label: 'Tower_Type_Recognition_Det',link:'Tower'},
    { value: 'Sector_Assembly_Sample',   label: 'Sector_Assembly_Det',link:'Tower'},
    { value: 'Antenna_Unused_Port_Sealing_Sample', label: 'Antenna_Unused_Port_Sealing_Det', link:'Antenna'},
    { value: 'Antenna_Unused_RF_Port_Weatherproofing_Sample',     label: 'Antenna_Unused_RF_Port_Weatherproofing_Det',link:'Antenna' },
    { value: 'Antenna_Unused_RF_Port_Count_Sample', label: 'Antenna_Unused_RF_Port_Count_Det', link:'Antenna'},
    { value: 'Azimuth_Reading_Sample',  label: 'Azimuth_Reading_Det' ,link:'Antenna'},
    { value: 'MW_Dish_Grounding_G2_Sample', label: 'MW_Dish_Grounding_G2_Det', link:'MW-TRM'},
    { value: 'MW_Dish_Arm_Mounting_G4_Sample', label: 'MW_Dish_Arm_Mounting_G4_Det', link:'MW-TRM'},
    { value: 'MW_Dish_Mounting_Sample', label: 'MW_Dish_Mounting_Det', link:'MW-TRM'},
    { value: 'MW_Dish_Small_Size_Mounting_Grouping_7_Sample', label: 'MW_Dish_Small_Size_Mounting_Grouping_7_Det', link:'MW-TRM'},
    { value: 'MW_RAU_Weatherproofing_Grouping_N_Sample', label: 'MW_RAU_Weatherproofing_Grouping_N_Det', link:'MW-TRM'},
    { value: 'Tape_Measure_Presence_Sample', label: 'Tape_Measure_Presence_Det', link:'Antenna'},
    { value: 'Radio_Unused_Port_Sealing_Sample', label: 'Radio_Unused_Port_Sealing_Det', link:'Radio'},
    { value: 'Radio_Unused_RF_Port_Weatherproofing_Sample',     label: 'Radio_Unused_RF_Port_Weatherproofing_Det',link:'Radio' },
    { value: 'Radio_Unused_RF_Port_Count_Sample', label: 'Radio_Unused_RF_Port_Count_Det', link:'Radio'},
    { value: 'Power_Supply_Unit_Mounting_Sample', label: 'Power_Supply_Unit_Mounting_Det', link:'Cabinet'},
    { value: 'Rectifier_Detection_Sample', label: 'Rectifier_Detection_Det', link:'Cabinet'},
    { value: 'Zeus_TowerID_Extraction_Sample', label: 'Zeus_TowerID_Extraction_Det', link:'General and OCR'}

  ];

   const Subcategory_options = [
    { value: 'Antenna_Coverage_Area_Det',  label: 'Antenna', Sample:'Antenna_Coverage_Area_Sample', link: 'QAS'},
    { value: 'Baseband_Mounting_Det',  label: 'Baseband', Sample:'Baseband_Mounting_Sample', link: 'QAS'},
    { value: 'Generic_OCR_Det',  label: 'General and OCR', Sample:'Generic_OCR_Sample',link: 'QAS'},	
    { value: 'Cabinet_Installed_Equipment_Defect_Det',  label: 'Cabinet', Sample:'Cabinet_Installed_Equipment_Defect_Sample',link: 'QAS'},
    { value: 'GPS_Cable_Routing_Det',  label: 'GPS', Sample:'GPS_Cable_Routing_Sample',link: 'QAS'},
    { value: 'Blurred_Image_Det', label: 'Image quality', Sample:'Blurred_Image_Sample',link: 'Common'},
    { value: 'PPE_Helmet_Vest_Shoe_Det',  label: 'PPE', Sample:'PPE_Helmet_Vest_Shoe_Sample',link: 'EHS'},
    { value: 'MW_Dish_Grounding_G2_Det',  label: 'MW-TRM', Sample:'MW_Dish_Grounding_G2_Sample', link: 'QAS'},
    { value: 'LLM_Antenna_Azimuth_Validation_Det',  label: 'LM-PoC', Sample:'LLM_Antenna_Azimuth_Validation_Sample', link: 'QAS'},
    { value: 'Radio_Mounting_Det',  label: 'Radio', Sample:'Radio_Mounting_Sample',link:'QAS'},
    { value: 'Crane_Drop_Zone_Det',  label: 'Tools, kits and OCR', Sample:'Crane_Drop_Zone_Sample',link:'EHS'},
    { value: 'Tower_Installed_Equipment_Defect_Det',  label: 'Tower', Sample:'Tower_Installed_Equipment_Defect_Sample',link:'QAS'},  
    { value: 'Vehicle_Airbags_Det',  label: 'Vehicle', Sample:'Vehicle_Airbags_Sample',link:'EHS'},
    { value: 'PPE_Rigger_Video_Det',  label: 'Video File Analysis', Sample:'PPE_Rigger_Video_Sample',link:'EHS'}
   ]

  const sample_options = [
     { value: 'Generic_OCR_Sample', label: 'Generic_OCR_Sample'}, 
     { value: 'Antenna_Label_Reading_Sample', label: 'Antenna_Label_Reading_Sample'},
     { value: 'Antenna_Coverage_Area_Sample',    label: 'Antenna_Coverage_Area_Sample' },
     { value: 'LLM_Antenna_Azimuth_Validation_Sample',    label: 'LLM_Antenna_Azimuth_Validation_Sample' },
     { value: 'Antenna_Front_Facing_Sample',    label: 'Antenna_Front_Facing_Sample' },
     { value: 'Antenna_Grounding_Grouping_2_Sample',    label: 'Antenna_Grounding_Grouping_2_Sample' },
     { value: 'Antenna_Height_Reading_Sample',    label: 'Antenna_Height_Reading_Sample' },
     { value: 'Antenna_Mounting_Sample',     label: 'Antenna_Mounting_Sample' },
     { value: 'Antenna_Mounting_Sample',     label: 'Antenna_Mounting_Sample' },
     { value: 'Antenna_Mounting_Grouping_3_Sample',     label: 'Antenna_Mounting_Grouping_3_Sample' },
     { value: 'Antenna_Mounting_Torque_Mark_Checking_Sample', label: 'Antenna_Mounting_Torque_Mark_Checking_Sample' },
     { value: 'Antenna_Weatherproofing_Sample',     label: 'Antenna_Weatherproofing_Sample' },
     { value: 'Antenna_Unused_Port_Sealing_Sample', label: 'Antenna_Unused_Port_Sealing_Sample'},
     { value: 'Azimuth_Reading_Sample', label: 'Azimuth_Reading_Sample'},
     { value: 'Battery_Defect_Sample',  label: 'Battery_Defect_Sample'},
     { value: 'Battery_Presence_Sample',  label: 'Battery_Presence_Sample'},
     { value: 'QR_And_Barcode_Available_Sample',  label: 'QR_And_Barcode_Available_Sample'},
     { value: 'QR_And_Barcode_Reader_Sample',  label: 'QR_And_Barcode_Reader_Sample'},
     { value: 'Busbar_Front_Sample',  label: 'Busbar_Front_Sample'},
     { value: 'Baseband_Mounting_Sample',     label: 'Baseband_Mounting_Sample' },
     { value: 'Baseband_Mounting_Grouping_3_Sample',     label: 'Baseband_Mounting_Grouping_3_Sample' },
     { value: 'Baseband_Mounting_And_Unused_Port_Checking_Grouping_3_Sample',     label: 'Baseband_Mounting_And_Unused_Port_Checking_Grouping_3_Sample' },
     { value: 'Baseband_Grounding_Sample',   label: 'Baseband_Grounding_Sample' },
     { value: 'Grounding_Busbar_Sample',   label: 'Grounding_Busbar_Sample' },
     { value: 'Baseband_Grounding_Grouping_3_Sample',   label: 'Baseband_Grounding_Grouping_3_Sample' },
     { value: 'Baseband_Unused_Port_Sealing_Sample',   label: 'Baseband_Unused_Port_Sealing_Sample' },
     { value: 'Baseband_Mounting_Ext_Sample', label: 'Baseband_Mounting_Ext_Sample'},
     { value: 'Baseband_Grounding_Ext_Sample', label: 'Baseband_Grounding_Ext_Sample'},
     { value: 'Baseband_GPS_Cable_Ext_Sample', label: 'Baseband_GPS_Cable_Ext_Sample'},
     { value: 'Baseframe_Cabinet_Mounting_Grouping_6_Sample', label: 'Baseframe_Cabinet_Mounting_Grouping_6_Sample'},
     { value: 'Blurred_Image_Sample', label: 'Blurred_Image_Sample'},
     { value: 'Cabinet_Installed_Equipment_Defect_Sample',   label: 'Cabinet_Installed_Equipment_Defect_Sample' },
     { value: 'Cabinet_Installed_Equipment_Recognition_Sample',   label: 'Cabinet_Installed_Equipment_Recognition_Sample' },
     { value: 'Cabinet_Leveling_Grouping_3_Sample',   label: 'Cabinet_Leveling_Grouping_3_Sample' },
     { value: 'Cable_Run_Checking_Sample',   label: 'Cable_Run_Checking_Sample' },
     { value: 'Cable_Inlet_Presence_Sample',   label: 'Cable_Inlet_Presence_Sample' },
     { value: 'Circuit_Breaker_Connection_Sample',   label: 'Circuit_Breaker_Connection_Sample' },
     { value: 'Cable_Clamp_Checking_Sample',  label: 'Cable_Clamp_Checking_Sample'},
     { value: 'Cable_Tie_Checking_Sample',  label: 'Cable_Tie_Checking_Sample'},
     { value: 'Cabling_Inside_Cabinet_Sample',  label: 'Cabling_Inside_Cabinet_Sample'},
     { value: 'Color_Sticker_Sample',  label: 'Color_Sticker_Sample'},
     { value: 'DDF_Detection_Sample',  label: 'DDF_Detection_Sample'},
     { value: 'Door_Status_Checking_Sample',  label: 'Door_Status_Checking_Sample'},
     { value: 'Earthing_Kit_And_Grounding_Grouping_5_Sample',  label: 'Earthing_Kit_And_Grounding_Grouping_5_Sample'},
     { value: 'Foundation_Of_Cabinet_Sample', label: 'Foundation_Of_Cabinet_Sample'},
     { value: 'GPS_Cable_Routing_Sample',  label: 'GPS_Cable_Routing_Sample' },
     { value: 'GPS_Antenna_Grounding_Sample',  label: 'GPS_Antenna_Grounding_Sample' },
     { value: 'GPS_Main_Grounding_Sample',  label: 'GPS_Main_Grounding_Sample' },
     { value: 'GPS_Grounding_Grouping_2_Sample',  label: 'GPS_Grounding_Grouping_2_Sample' },
     { value: 'GPS_Mounting_Sample', label:'GPS_Mounting_Sample'},
     { value: 'GPS_Weatherproofing_Sample', label:'GPS_Weatherproofing_Sample'},   
     { value: 'Grounding_Sample',      label: 'Grounding_Sample' },
     { value: 'KWH_Meter_Presence_Sample',      label: 'KWH_Meter_Presence_Sample' },
     { value: 'Jumper_Color_Code_Sample',      label: 'Jumper_Color_Code_Sample' },
     { value: 'Level_Check_Sample',      label: 'Level_Check_Sample' },
     { value: 'Loose_Hardware_Sample', label: 'Loose_Hardware_Sample'},
     { value: 'Manufacture_Label_Reading_Sample', label: 'Manufacture_Label_Reading_Sample'},
     { value: 'MCB_Detection_Sample', label: 'MCB_Detection_Sample'},
     { value: 'Meter_Reading_Sample', label: 'Meter_Reading_Sample'},
     { value: 'MW_Dish_Arm_Mounting_G4_Sample', label: 'MW_Dish_Arm_Mounting_G4_Sample'},
     { value: 'MW_Dish_Grounding_G2_Sample', label: 'MW_Dish_Grounding_G2_Sample'},
     { value: 'MW_Dish_Mounting_Sample', label: 'MW_Dish_Mounting_Sample'},
     { value: 'MW_Dish_Small_Size_Mounting_Grouping_7_Sample', label: 'MW_Dish_Small_Size_Mounting_Grouping_7_Sample'},
     { value: 'MW_RAU_Grounding_Grouping_N_Sample', label: 'MW_RAU_Grounding_Grouping_N_Sample'},
     { value: 'MW_RAU_Weatherproofing_Grouping_N_Sample', label: 'MW_RAU_Weatherproofing_Grouping_N_Sample'},
     { value: 'Nameplate_Data_Recognition_Sample', label: 'Nameplate_Data_Recognition_Sample'},
     { value: 'Passive_Tagging_Sample', label: 'Passive_Tagging_Sample'},
     { value: 'PPE_Safety_Checking_Sample',  label: 'PPE_Safety_Checking_Sample' },
     { value: 'PPE_Helmet_Vest_Shoe_Sample',  label: 'PPE_Helmet_Vest_Shoe_Sample' },
     { value: 'PPE_Helmet_Shoes_Sample',  label: 'PPE_Helmet_Shoes_Sample' },
     { value: 'PPE_Helmet_Harness_Gloves_Shoes_Sample', label: 'PPE_Helmet_Harness_Gloves_Shoes_Sample'},
     { value: 'PPE_Rigger_Sample', label: 'PPE_Rigger_Sample'},
     { value: 'PPE_Climbing_Checking_Video_Sample', label: 'PPE_Climbing_Checking_Video_Sample'},
     { value: 'PPE_Rigger_Video_Sample', label: 'PPE_Rigger_Video_Sample'},
     { value: 'PPE_Rigger_And_Safety_Checking_Video_Sample', label: 'PPE_Rigger_And_Safety_Checking_Video_Sample'},
     { value: 'PPE_Rigger_Without_Vest_Sample', label: 'PPE_Rigger_Without_Vest_Sample'},
     { value: 'PPE_Electrical_Sample', label: 'PPE_Electrical_Sample'},
     { value: 'Caution_Tape_Checking_Sample', label: 'Caution_Tape_Checking_Sample'},
     { value: 'Crane_Drop_Zone_Sample', label: 'Crane_Drop_Zone_Sample'},
     { value: 'Crane_Position_Sample', label: 'Crane_Position_Sample'},
     { value: 'Emergency_Plan_Banner_Sample', label: 'Emergency_Plan_Banner_Sample'},
     { value: 'Hand_Held_Tools_Checking_Sample', label: 'Hand_Held_Tools_Checking_Sample'},
     { value: 'First_Aid_Box_Sample', label: 'First_Aid_Box_Sample'},
     { value: 'Fire_Extinguisher_Sample', label: 'Fire_Extinguisher_Sample'},
     { value: 'Indoor_TRM_Mounting_And_Unused_Ports_Checking_Grouping_3_Sample',    label: 'Indoor_TRM_Mounting_And_Unused_Ports_Checking_Grouping_3_Sample'},
     { value: 'LLM_Site_Access_Detection_Sample', label: 'LLM_Site_Access_Detection_Sample'},
     { value: 'LLM_Spare_1_Sample', label: 'LLM_Spare_1_Sample'},
     { value: 'Vehicle_Airbags_Sample', label: 'Vehicle_Airbags_Sample'},
     { value: 'Vehicle_Light_Windshield_Licenseplate_Sample', label: 'Vehicle_Light_Windshield_Licenseplate_Sample'},
     { value: 'Vehicle_Odometer_Sample', label: 'Vehicle_Odometer_Sample'},
     { value: 'Vehicle_Seatbelts_Sample', label: 'Vehicle_Seatbelts_Sample'},
     { value: 'Vehicle_Tyre_Sample', label: 'Vehicle_Tyre_Sample'},
     { value: 'Vertical_Power_Cable_Color_Code_Sample', label: 'Vertical_Power_Cable_Color_Code_Sample'},
     { value: 'Vin_Extraction_Sample', label: 'Vin_Extraction_Sample'},
     { value: 'Safe_Work_Info_Verify_Sample', label: 'Safe_Work_Info_Verify_Sample'},
     { value: 'Radio_Mounting_Sample',     label: 'Raido_Mounting_Sample' },
     { value: 'Radio_Mounting_Grouping_3_Sample',     label: 'Radio_Mounting_Grouping_3_Sample' },
     { value: 'Radio_Grounding_Sample',     label: 'Radio_Grounding_Sample' },
     { value: 'Radio_Grounding_Grouping_2_Sample',     label: 'Radio_Grounding_Grouping_2_Sample' },
     { value: 'Radio_Weatherproofing_Sample',     label: 'Radio_Weatherproofing_Sample' },
     { value: 'Radio_Unused_Port_Sealing_Sample', label: 'Radio_Unused_Port_Sealing_Sample'},
     { value: 'RBS_Main_Grounding_Grouping_2_Sample', label: 'RBS_Main_Grounding_Grouping_2_Sample'},
     { value: 'Rectifier_Detection_Sample', label: 'Rectifier_Detection_Sample'},
     { value: 'Rigging_Kit_Checking_Sample',  label: 'Rigging_Kit_Checking_Sample' },
     { value: 'Rigging_Kit_1_Pulley_Sample',  label: 'Rigging_Kit_1_Pulley_Sample' },
     { value: 'Rigging_Kit_2_Pulley_Sample',  label: 'Rigging_Kit_2_Pulley_Sample' },
     { value: 'Rigging_Kit_3_Pulley_Sample',  label: 'Rigging_Kit_3_Pulley_Sample' },
     { value: 'ROI_Sample',            label: 'ROI_Sample' },
     { value: 'Roxtec_Defect_Sample',  label: 'Roxtec_Defect_Sample' },
     { value: 'Site_Access_Detection_Sample',  label: 'Site_Access_Detection_Sample' },
     { value: 'Site_Data_Extraction_From_Image_Sample', label: 'Site_Data_Extraction_From_Image_Sample'},
     { value: 'Site_Entrance_Checking_Sample', label: 'Site_Entrance_Checking_Sample'},
     { value: 'Sticker_Reading_Sample', label: 'Sticker_Reading_Sample'},
     { value: 'SRS_Screenshot_Grouping_4_Sample', label: 'SRS_Screenshot_Grouping_4_Sample'},
     { value: 'Tape_Measure_Presence_Sample', label: 'Tape_Measure_Presence_Sample'},
     { value: 'Thermometer_Reading_Sample', label: 'Thermometer_Reading_Sample'},
     { value: 'Tilt_Meter_Presence_Sample', label: 'Tilt_Meter_Presence_Sample'},
     { value: 'Tower_Installed_Equipment_Defect_Sample',    label: 'Tower_Installed_Equipment_Defect_Sample'},
     { value: 'Tower_Installed_Equipment_Recognition_Sample',    label: 'Tower_Installed_Equipment_Recognition_Sample'},
     { value: 'Tower_Type_Recognition_Sample',    label: 'Tower_Type_Recognition_Sample'},
     { value: 'TRM_Mounting_Grouping_3_Sample',    label: 'TRM_Mounting_Grouping_3_Sample'},
     { value: 'Sector_Assembly_Sample',    label: 'Sector_Assembly_Sample'},
     { value: 'Unused_RF_Port_Weatherproofing_Sample',     label: 'Unused_RF_Port_Weatherproofing_Sample' },
     { value: 'Unused_RF_Port_Count_Sample',      label: 'Unused_RF_Port_Count_Sample' },
     { value: 'Power_Supply_Unit_Mounting_Sample',      label: 'Power_Supply_Unit_Mounting_Sample' },
     { value: 'Zeus_TowerID_Extraction_Sample',      label: 'Zeus_TowerID_Extraction_Sample' }
  ];

  const Markcolour = [
    { value: 'Red',  label: 'Red'},
    { value: 'Yellow',  label: 'Yellow'},
    { value: 'Blue',  label: 'Blue'},	
    { value: 'Green',  label: 'Green'},
    { value: 'Black',  label: 'Black'}
  ]

  const Market = [
      { value: 'MMEA', label: 'MMEA'},
      { value: 'MELA', label: 'MELA'},
      { value: 'MOAI', label: 'MOAI'},
      { value: 'other market', label: 'other market'}
  ]
  const groupingUC = ['Caution_Tape_Checking_Det','PPE_Rigger_Det','PPE_Electrical_Det','Hand_Held_Tools_Checking_Det','Vehicle_Light_Windshield_Licenseplate_Det',
'Vehicle_Tyre_Det','Vehicle_Airbags_Det','Vehicle_Seatbelts_Det','First_Aid_Box_Det','Fire_Extinguisher_Det','Rigging_Kit_Checking_Det','Antenna_Grounding_Grouping_2_Det',
'Radio_Grounding_Grouping_2_Det','Rigging_Kit_1_Pulley_Det','Rigging_Kit_2_Pulley_Det','Rigging_Kit_3_Pulley_Det','msGPS_Grounding_Grouping_2_Det','Radio_Mounting_Grouping_3_Det',
'Antenna_Mounting_Grouping_3_Det','Baseband_Mounting_Grouping_3_Det','Baseband_Grounding_Grouping_3_Det','PPE_Rigger_Without_Vest_Det','Crane_Drop_Zone_Det',
'Crane_Position_Det','MW_Dish_Grounding_G2_Det','SRS_Screenshot_Grouping_4_Det','Cabinet_Leveling_Grouping_3_Det','TRM_Mounting_Grouping_3_Det','Baseframe_Cabinet_Mounting_Grouping_6_Det',
'LLM_Antenna_Azimuth_Validation_Det','RBS_Main_Grounding_Grouping_2_Det','Baseband_Mounting_And_Unused_Port_Checking_Grouping_3_Det','Earthing_Kit_And_Grounding_Grouping_5_Det','MW_RAU_Weatherproofing_Grouping_N_Det',
'Indoor_TRM_Mounting_And_Unused_Ports_Checking_Grouping_3_Det','MW_Dish_Small_Size_Mounting_Grouping_7_Det','MW_RAU_Grounding_Grouping_N_Det','MW_Dish_Arm_Mounting_G4_Det']
  const needMarketUC = ['Caution_Tape_Checking_Det','Antenna_Weatherproofing_Det','Radio_Weatherproofing_Det','Site_Data_Extraction_From_Image_Det','GPS_Antenna_Grounding_Det','Circuit_Breaker_Connection_Det',]
  const needSectorIDBandInfoUC = ['LLM_Antenna_Azimuth_Validation_Det']
  const videoUC = ['PPE_Rigger_Video_Det','Worker_Identification_Det','PPE_Rigger_And_Safety_Checking_Video_Det','PPE_Climbing_Checking_Video_Det']
  let allLabels = [];
  let allLabelDict = {};
const FileUploader = (props) => {
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [currentFile, setCurrentFile] = useState(undefined);
    const [fileLabelText, setFileLabelText] = useState("Choose file");
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [messageDet, setMessageDet] = useState("");
    const [fileInfos, setFileInfos] = useState([]);
    const [selectedUCType, setSelectedUC] = useState(undefined);
    const [selectedCategory, setSelectedCategory] = useState(undefined);
    const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [selectedSampleOption, setSelectedSampleOption] = useState(undefined);
    const [selectedSamplebase64, setSelectedSamplebase64] = useState(undefined);
    const [selectedType, setSelectedType] = useState(undefined);
    const [selectedTorqueMark, setSelectedTorqueMark] = useState(undefined);
    const [selectedPDF, setSelectedPDF] = useState(undefined);
    const [selectedMarkColour, setSelectedMarkColour] = useState(undefined);
    const [approvedChecked, setApprovedChecked] = useState(true);
    const [rejectedChecked, setRejectedChecked] = useState(true);
    const [selectedURL, setSelectedURL] = useState(undefined);
    const [selectedMarket, setSelectedMarket] = useState(undefined);
    const [selectedNeedMarket, setSelectedNeedMarket] = useState(undefined);
    const [selectedNeedSectorIDBandInfo, setSelectedNeedSectorIDBandInfo] = useState(undefined);
    const [selectedNeedURL, setSelectedNeedURL] = useState(undefined);
    const [sectorIDInput, setSectorIDInput] = useState('');
    const [bandInfoInput, setBandInfoInput] = useState('');
    const [URLInput, setURLInput] = useState('');
    const [isValidURL, setIsValidURL] = useState(true);
    const [annotationIsNull, setannotationIsNull] = useState(true);
    const [labelDisplayed, setLabelDisplayed] = useState(true);

    const filteredOptions = options.filter(
        (o) => o.link === selectedUCType
        );

    const filteredSubCategory = Subcategory_options.filter(
        (o) => o.link === selectedSubCategory
    );
    

    const handleSectorIDInputChange = (event) => {
        setSectorIDInput(event.target.value);
      };

    const handleBandInfoInputChange = (event) => {
        setBandInfoInput(event.target.value);
      };
    const handleURLInputChange = (event) => {
        setURLInput(event.target.value);
        setIsValidURL(validateURL(event.target.value));
        props.onVideoURL(event.target.value);
        setSelectedFiles("");
        setFileLabelText("")
      }; 
    const validateURL = (url) => {
        const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return regex.test(url);
    }; 
    const clearURLInput = () => {
        setURLInput('');
        props.onVideoURL("");
        
    };
      
    const handleCategoryOption = (category) => {
        setSelectedCategory(category);
        setSelectedSubCategory(category['label'])
        setSelectedType({label:category['value'],value:category['UC'],Sample:category['Sample'],link: category['label']});
        handleTypeOption({label:category['value'],value:category['UC'],Sample:category['Sample'],link: category['label']})
        if(props.imgBase64 !== undefined){
           props.onCleanBase64();
        }
        if(props.docURL !== undefined){
            props.onCleanURL();
        }
        setSelectedOption({label:category['UC'], value:category['Sample'], link:category['value']});
        handleOptionChange({label:category['UC'], value:category['Sample'], link:category['value']});
        setSelectedSampleOption([{value:category['Sample'], label:category['Sample']}]);
        handleSampleOptionChange({value:category['Sample'], label:category['Sample']});
    }
    const handleMarkColour = (colour) => {
        setSelectedMarkColour(colour);
    }

    const handleMarketSelect = (market) => {
        setSelectedMarket(market);
    }
    const handleTypeOption = (type_option) => {
        setSelectedType(type_option);
        setSelectedUC(type_option['label']);
        console.log(filteredOptions);
        console.log(selectedOption);
        if(props.imgBase64 !== undefined){
           props.onCleanBase64();
        }
        if(props.docURL !== undefined){
            props.onCleanURL();
        }
        setSelectedOption({label:type_option['value'], value:type_option['Sample'], link:type_option['label']});
        handleOptionChange({label:type_option['value'], value:type_option['Sample'], link:type_option['label']});
        if (type_option['value'] !== 'Worker_Identification_Det'){
            setSelectedSampleOption([{value:type_option['Sample'], label:type_option['Sample']}]);
            console.log(selectedSampleOption)
            handleSampleOptionChange({value:type_option['Sample'], label:type_option['Sample']});
            }
      };

      const handleOptionChange = (option) => {
        setSelectedOption(option);
        console.log(option['label'])
        if(props.imgBase64 !== undefined){
            props.onCleanBase64();
         }
        if(props.docURL !== undefined){
            props.onCleanURL();
         }
        if (option['label'] !== 'Worker_Identification_Det'){
        setSelectedSampleOption([{value:option['value'], label:option['value']}])
        console.log(selectedSampleOption);
        handleSampleOptionChange({value:option['value'], label:option['value']});
        }
        setSelectedTorqueMark(false);
        setSelectedPDF(false);
        setSelectedNeedMarket(false);
        setSelectedNeedSectorIDBandInfo(false);
        setSelectedNeedURL(false);
        setURLInput("");
    
        
        if (option['label'] === 'Antenna_Mounting_Torque_Mark_Checking_Det'){
            setSelectedTorqueMark(true);
        }
        else if (option['label'] === 'Safe_Work_Info_Verify_Det'){
            setSelectedPDF(true);
           
        }
        else if(needSectorIDBandInfoUC.includes(option['label'])){
            setSelectedNeedSectorIDBandInfo(true);
        }
        else if(videoUC.includes(option['label'])){
            setSelectedNeedURL(true);
        }
        else if (needMarketUC.includes(option['label'])){
            setSelectedNeedMarket(true);
            console.log(selectedNeedMarket);
            setSelectedMarket({value:"MMEA",label:"MMEA"});
            switch (option['label']){
                case "Site_Data_Extraction_From_Image_Det":
                    setSelectedMarket({value:"MOAI",label:"MOAI"});
            }
        }
        console.log(`Option selected:`, option);
        const buttons = document.querySelectorAll('.toggleButton');
        buttons.forEach(button => {
        button.remove(); 
});
      };
   
    const handleSampleOptionChange = (Sample_option) => {
        setSelectedSampleOption(Sample_option);
        console.log(`Sample Option selected:`, Sample_option);
        console.log(Sample_option['label'])
        let sample_src = Sample_option['label'];
        console.log(Sample_option['value']);
        console.log(selectedOption)
        if(props.imgBase64 !== undefined){
            props.onCleanBase64()
         }    
         if(props.docURL !== undefined){
            props.onCleanURL()
         }
        props.onSampleSelect(sample_src);
        setSelectedFiles("")
        setURLInput("");
        setFileLabelText("")
        setCurrentFile(undefined)
        setMessage("")
        setMessageDet("")
        setProgress(0)
        setTimeout("")
        console.log(selectedFiles);
        document.getElementById("inputGroupFile01").value = ""     
      };

   const selectApproved = () =>{
    setApprovedChecked((approvedState) => !approvedState);
    console.log(approvedChecked)
    props.onSelectApproved(approvedChecked);
   }

   const selectRejected = () =>{
    setRejectedChecked((rejectedState) => !rejectedState);
    console.log(rejectedChecked)
    props.onSelectRejected(rejectedChecked); 
   }
  
   
   useEffect(() => {
    console.log('Label displayed state:', labelDisplayed);
    props.onSelectLabelDisplayed(labelDisplayed); 
  }, [labelDisplayed]); 

  const selectLabelDisplayed = () => {
    setLabelDisplayed((prevLabelDisplayed) => !prevLabelDisplayed); 
};

  

    const selectFile = (event) => {
        if(event.target.files.length > 0) {
            setSelectedFiles(event.target.files);
            var filenames = '';
            for(let i =0; i < event.target.files.length; i++){
               filenames += '"'+ event.target.files[i].name + '" '
            }
            setFileLabelText(filenames)
            props.onFileSelect(event.target.files)
            props.onFileNameSelect(event.target.files[0].name)
            setSelectedSampleOption("")
            setURLInput("")
            setSelectedSamplebase64(undefined);
            setSelectedURL(undefined);
            setMessageDet("")
            setMessage("")
            setProgress(0)
            const buttons = document.querySelectorAll('.toggleButton');
            buttons.forEach(button => {
            button.remove(); 
            });
            
        }
        console.log(fileLabelText)
        console.log(selectedSampleOption)
      };

    function handleResponse(imageList,response,option) {
        var detMsg = "";
        console.log(response);
        props.onPostResponse(response);
        setProgress(100);
        let timestampList = [];
        let video_labels = [];
        let labels = [];
        let clickedLabels = [];
        let labelDict = {};
        if (response.status === 200) {
            setMessage("INFERENCE DONE!");
            }
        else {
            setMessage("INFERENCE FAILED!");
            }
        if(videoUC.includes(option) ) {
            let detectionResult = response.data['jsonData']['result'];
            console.log(detectionResult);
            for (let j = 0, dlen = detectionResult.length; j < dlen; j++) {
                let conclusions = detectionResult[j]['conclusions'];
                let annotations = detectionResult[j]['annotations'];
                if(annotations.length > 0){
                    setannotationIsNull(false);
                }
                else {
                    setannotationIsNull(true);
                }
                for (let p = 0; p < annotations.length; p++) {
                    if (annotations[p].hasOwnProperty('timestamp') && annotations[p].timestamp) {
                       const timestamp = annotations[p].timestamp;
                       if (!timestampList.includes(timestamp)) {
                       timestampList.push(timestamp);            
                       }
                }
                const label = annotations[p].label;
                const isDefect = annotations[p].isDefect;
                if (!(label in labelDict)) {
                    labelDict[label] = isDefect;
                }
                if (!video_labels.includes(label)){
                    video_labels.push(label);
                }
                console.log(labelDict);
                console.log(video_labels);
            }  
                props.onScreenShotCount(timestampList.length);
                let result = detectionResult[j]['result'];
                for (let k = 0, clen = conclusions.length; k < clen; k++) {
                    detMsg += '**' + conclusions[k]['category'] + ':   ' +conclusions[k]['result']  + '\n';
                            }
                detMsg += 'This video is ' + result + '\n';
              }
                setMessageDet(detMsg);
                generateLabelButton(video_labels,clickedLabels,labelDict);
                allLabels = video_labels;
                allLabelDict = labelDict;
                
            }
///////////////for non-Video UC///////////
        if(!videoUC.includes(option)){
            let detectionResult = response.data['jsonData']['result'];
            if ((imageList !== undefined) && Array.isArray(imageList)){
                const map = new Map(imageList.map(obj => [obj.name, obj]));
                detectionResult.sort((a, b) => {
                    const objA = map.get(a.imageName);
                    const objB = map.get(b.imageName);
                return imageList.indexOf(objA) - imageList.indexOf(objB);
            });
        }
            console.log(detectionResult);
            for (let j = 0, dlen = detectionResult.length; j < dlen; j++) {
                let conclusions = detectionResult[j]['conclusions'];
                let annotations = detectionResult[j]['annotations'];
                if(annotations.length > 0){
                    setannotationIsNull(false);  
                }
                else {
                    setannotationIsNull(true);
                }
                console.log(annotationIsNull);
                let result = detectionResult[j]['result'];
                let reason = detectionResult[j]['reason'];
                for (let i =0, alen = annotations.length; i< alen; i++){
                    const label = annotations[i].label;
                    const isDefect = annotations[i].isDefect;
                    if (!(label in labelDict)) {
                        labelDict[label] = isDefect;
                    }
                    if (!labels.includes(annotations[i].label)){
                       labels.push(annotations[i].label);
                    }
                }
               
                for (let k = 0, clen = conclusions.length; k < clen; k++) {
                    detMsg += '**' + conclusions[k]['category'] + ':   ' +conclusions[k]['result']  + '\n';
                            }
                    if (detectionResult.length > 1) {
                    detMsg += 'Image ' + (j+1) + ' is ' + result + '\n';
                    detMsg += '\n';
                    }
                    else if (detectionResult.length ===1){
                        if((reason == '') || (result == "Approved"))
                        {detMsg += result + '\n'}
                        else if((result == "Rejected") ||(result == "NA")){
                        detMsg += result + ':' + reason + '\n';
                        }
                    }
                }
               setMessageDet(detMsg);
               generateLabelButton(labels,clickedLabels,labelDict);
               allLabels = labels;
               allLabelDict = labelDict;
            }
            return(detMsg)
    }
    function generateLabelButton(labels,clickedLabels,labelDict){
        const buttonContainer = document.getElementById('buttonContainer');
        labels.forEach((label, index) => {
            const button = document.createElement('button');
            button.innerText = label;
            button.classList.add('toggleButton');
            clickedLabels.push(label);
            button.classList.add('active'); 
            if (labelDict[label] === true) {
                button.style.border = '2px solid red';
                button.style.backgroundColor = 'red';
            } else {
                button.style.backgroundColor = 'green'; 
            }
            button.addEventListener('click', function() {
                if (clickedLabels.includes(label)) {
                    clickedLabels = clickedLabels.filter(l => l !== label);             
                    if (labelDict[label] === true) {
                        this.style.backgroundColor = 'gray';
                    } else {
                        this.style.backgroundColor = ''; 
                    }
                    this.classList.remove('active');
                } else {
                    clickedLabels.push(label);
                    if (labelDict[label] === true) {
                        this.style.backgroundColor = 'red';
                        this.style.border = '2px solid red';
                    } else {
                        this.classList.add('active');
                    }
                }
               
                props.onButtonState(clickedLabels); 
            });
                buttonContainer.appendChild(button);
                });  
                props.onButtonState(clickedLabels); 
    }
   
    const selectAll = () => {
        //console.log(allLabels);
        let clickedLabels= allLabels;
        props.onButtonState(clickedLabels); 
        const buttons = document.getElementById('buttonContainer').children;
        let LabelDict_all = allLabelDict;
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            const label = button.innerText;
            // clone button to remove existing event listener
            const newButton = button.cloneNode(true);
            if (LabelDict_all[label] === true) {
                newButton.style.border = '2px solid red';
                newButton.style.backgroundColor = 'red';
            } else {
                newButton.style.border = '';
                newButton.style.backgroundColor = 'green';
            }
            // replace original button
            button.replaceWith(newButton);
            // re-bind the clicked event
            newButton.addEventListener('click', function() {
                if (clickedLabels.includes(label)) {
                    clickedLabels = clickedLabels.filter(l => l !== label);
                    newButton.style.backgroundColor = 'grey';
                    if (LabelDict_all[label] === true) {
                        newButton.style.border = '2px solid red';
                    } else {
                        newButton.style.border = '';
                    }
                    newButton.classList.remove('active');
                } else {
                    clickedLabels.push(label);
                    if (LabelDict_all[label] === true) {
                        newButton.style.border = '2px solid red';
                        newButton.style.backgroundColor = 'red';
                    } else {
                        newButton.style.backgroundColor = 'green';
                    }
                    newButton.classList.add('active');
                }
                console.log(clickedLabels);
                props.onButtonState(clickedLabels);
            });
        }
    };

    const deselectAll = () => {
        props.onButtonState([]);
        let clickedLabels = [];
        let LabelDict_all = allLabelDict;
        const buttons = document.getElementById('buttonContainer').children;
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            const label = button.innerText;
            // clone button to remove existing event listener
            const newButton = button.cloneNode(true);
            if (LabelDict_all[label] === true) {
                newButton.style.border = '2px solid red';
                newButton.style.backgroundColor = 'grey';
            } else {
                newButton.style.border = '';
                newButton.style.backgroundColor = 'grey';
            }
            // replace original button
            button.replaceWith(newButton);
            // re-bind the clicked event
            newButton.addEventListener('click', function() {
                if (!clickedLabels.includes(label)) {
                    clickedLabels.push(label);
                    if (LabelDict_all[label] === true) {
                        newButton.style.border = '2px solid red';
                        newButton.style.backgroundColor = 'red';
                    } else {
                        newButton.style.backgroundColor = 'green';
                    }
                    newButton.classList.add('active');
                } else {
                    clickedLabels = clickedLabels.filter(l => l !== label);
                    newButton.style.backgroundColor = 'grey';
                    if (LabelDict_all[label] === true) {
                        newButton.style.border = '2px solid red';
                    } else {
                        newButton.style.border = '';
                    }
                    newButton.classList.remove('active');
                }
                console.log(clickedLabels);
                props.onButtonState(clickedLabels);
            });
        }
    };
   
    function upload() {
        var sampleImgInfo = props.imgBase64; // in sample images case, if this is defined!!!
        var sampleDocInfo = props.docURL;
        let promises = [];
        let fileNames = []
        let fileBase64 = []
        let Base64IfOneImage;
      
        setMessageDet("")
        setMessage("")
        setProgress(0)
        props.onCleanDetection();

        if (selectedFiles !== "") { //in browser image case
            var FileLength = selectedFiles.length;
            let option = selectedOption['label'];
            if (option === 'Antenna_Mounting_Torque_Mark_Checking_Det' && selectedMarkColour !== undefined) {
                option = "Antenna_Mounting_Torque_Mark_Checking_" + selectedMarkColour['value']
                }
            let marketArea = "NA";
            if (selectedMarket !== undefined){
                marketArea = selectedMarket['value'];
            }
            console.log(option);
            let imgName = selectedFiles[0].name;
            let imageInfo;
            let imageList = [];
            setCurrentFile(selectedFiles);
    
            async function readFile(file) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.substr(reader.result.lastIndexOf(",") + 1));
                    reader.onerror = (error) => reject(error);
                });
            }
            async function readFiles(selectedFiles) {          
                for (let file of selectedFiles) {
                    fileNames.push(file.name);
                    let base64String = await readFile(file);
                    promises.push(base64String);
                }
                return Promise.all(promises);
            }
                readFiles(selectedFiles).then(fileContents => {
                // fileContents will be an array containing
                // the contents of the files, perform the
                // character replacements and other transformations
                // here as needed
                for (let m = 0; m < fileContents.length; m++) {
                    fileBase64.push(fileContents[m])
                    var detail = {}
                    detail.name = fileNames[m]
                    detail.imageBase64 = fileContents[m]
                    imageList.push(detail)
                    console.log(imageList)
                    if (m === fileContents.length-1){
                        if (groupingUC.includes(option) || (FileLength>1)){
                            imageInfo = imageList;            
                            }
                        else if (FileLength === 1){
                            Base64IfOneImage = fileContents[0];
                            imageInfo = Base64IfOneImage;            
                        }
                        console.log(imageInfo);
                    }
                }
                    if(imageInfo == null){
                        setProgress(0);
                        setMessage("Please wait for loading the images!");
                    }
                    else {
                        uploadService.uploadDataAndFile1(imageInfo, imgName, option, marketArea,sectorIDInput, bandInfoInput,(event2)=> {
                            setProgress(50)
                            })
                            .then((response) => {
                             handleResponse(imageInfo,response,option);
                        })
                            .catch(() => {
                                setProgress(0);
                                setMessage("Could not upload the file!");
                                setCurrentFile(undefined);
                                });
                    }
                }                            
            ); 
}      
    else if(URLInput){
            let videoURL = URLInput;
            let imgName = "Sample.mp4";
            let option = selectedOption['label'];
            let marketArea = "";
            let sectorID = "";
            let bandInfo = "";
            uploadService.uploadDataAndFile1(videoURL, imgName, option, marketArea,sectorID, bandInfo, (event) => {
                setProgress(50)
            })
                .then((response) => {
                    handleResponse(videoURL = undefined, response,option)                           
            })
                .catch(() => {
                    setProgress(0);
                    setMessage("Could not upload the file!");
                    setCurrentFile(undefined);
                });  
    }
        else if(sampleImgInfo !== undefined ){
            setSelectedSamplebase64(sampleImgInfo);
                    let imgName = "Sample.jpg";
                    let option = selectedOption['label'];
                    if (option === 'Antenna_Mounting_Torque_Mark_Checking_Det' && selectedMarkColour !== undefined) {
                        option = "Antenna_Mounting_Torque_Mark_Checking_" + selectedMarkColour['value']
                    }
                    let marketArea = "NA";
                    console.log(selectedMarket);
                    if (selectedMarket !== undefined){
                        marketArea = selectedMarket['label'];
                        console.log(marketArea);
                    }
                    console.log(option);
                    console.log(sampleImgInfo);
                    if(sampleImgInfo.length == 0){
                        setProgress(0);
                        setMessage("Please wait for loading the images!");
                    }
                    else {
                    uploadService.uploadDataAndFile1(sampleImgInfo, imgName, option, marketArea, sectorIDInput,bandInfoInput,(event) => {
                        setProgress(50)
                    })
                        .then((response) => {
                            handleResponse(sampleImgInfo= undefined,response,option)                      
                    })
                        .catch(() => {
                            setProgress(0);
                            setMessage("Could not upload the file!");
                            setCurrentFile(undefined);
                        });
                    }
                    }
        else if(sampleDocInfo !== undefined){
            setSelectedURL(sampleDocInfo);
            let imgName = "Sample.pdf";
            console.log(sampleDocInfo);
            let option = selectedOption['label'];
            let marketArea = "";
            let sectorID = "";
            let bandInfo = "";
            uploadService.uploadDataAndFile1(sampleDocInfo, imgName, option, marketArea,sectorID, bandInfo, (event) => {
                setProgress(50)
            })
                .then((response) => {
                    handleResponse(sampleDocInfo = undefined, response,option)                           
            })
                .catch(() => {
                    setProgress(0);
                    setMessage("Could not upload the file!");
                    setCurrentFile(undefined);
                });    
        }
    }

    function CheckBeforeUpload() {
        var msg = "Sample image type doesn't match with UC inference type. Are you sure to proceed?\n\nPlease confirm!";
        console.log(selectedOption['value']);
        console.log(selectedSampleOption['label'])
        const buttons = document.querySelectorAll('.toggleButton');
            buttons.forEach(button => {
            button.remove(); 
            });
        if ((selectedSampleOption!== "") && (selectedSampleOption['label'] !== selectedOption['value'])){
           if (window.confirm(msg)==true){
              upload();
              return true;
            }else
            {
         return false;
            }
        }
       else {
           upload();
       }
    }
    let list = undefined
    if( fileInfos.length !== 0 ) {
        list = fileInfos.map((file, index) => (
            <li className="list-group-item" key={index}>
                {file.name}
            </li>
            ))
    }
    else {
        list = [(<li className="list-group-item" key={0}>None</li>)]
    }

    return (
            <div >              
                <div className="container my-3" style={{position:"relative"}}>
                    <div className="input-group my-3" style={{ fontSize: "13px" }}  >
                       <div>Please select Category and UC to detect</div>
                    </div>           
                    <Select
                        value={selectedCategory}
                        onChange={handleCategoryOption}
                        options={categories}
                    />
                    <Select
                        value={selectedType}
                        onChange={handleTypeOption}
                        options={filteredSubCategory}
                    />
                    <Select
                        value={selectedOption}
                        onChange={handleOptionChange}
                        options= {filteredOptions}
                    />
             </div>
             {selectedTorqueMark && (
                       <div className="container my-3">
                       <div className="input-group my-3" style={{ fontSize: "13px", color: "red"}}>
                          <div>You need to choose the torque mark colour</div>
                       </div>
                       <Select
                           value={selectedMarkColour}
                           onChange={handleMarkColour}
                           options={Markcolour}
                      />
                     </div>
                    )}
             {selectedNeedMarket && (
                       <div className="container my-3">
                       <div className="input-group my-3" style={{ fontSize: "13px", color: "red"}}>
                          <div>Please choose the market for the UC</div>
                       </div>
                       <Select
                           value={selectedMarket}
                           onChange={handleMarketSelect}
                           options={Market}
                      />
                     </div>
                    )} 

            {selectedNeedSectorIDBandInfo && (
                <div className="container my-3">
                    <div>Please enter the Sector ID and Band Info:</div>
                <input 
                  type="text" 
                  value={sectorIDInput} 
                  onChange={handleSectorIDInputChange} 
                  placeholder="Sector ID:" 
                />
                 <input 
                  type="text" 
                  value={bandInfoInput} 
                  onChange={handleBandInfoInputChange} 
                  placeholder="Band Info:" 
                />    
              </div>
            )}

           {selectedNeedURL && (
                <div className="container my-3" style={{ position: 'relative', width: '100%' }}>
                    <div>Please input the URL of the video:</div>
                <input 
                  type="text" 
                  value={URLInput} 
                  onChange={handleURLInputChange}  
                  style={{ width: '100%', paddingRight: '30px' }}
                /> 
                 {URLInput && (
                    <span
                        onClick={clearURLInput}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            color: '#999',
                            fontSize: '18px',
                        }}
                    >
                        &times;
                    </span>
                )} 
                {!isValidURL && <div style={{color: 'red'}}>Please enter a valid URL.</div>}
              </div>
            )} 
            
            {!selectedNeedURL && (
                <div className="container my-3">
                    <div className="input-group my-3" style={{ fontSize: "13px" }}>
                       <div>Please select sample image to detect</div>
                    </div>
                    <div>
                    <Select
                        value={selectedSampleOption}
                        onChange={handleSampleOptionChange}
                        options={sample_options}
                   />
                   </div>
                    {(selectedSampleOption&& !selectedPDF) && (
                    <div class="container">                
                       <div style={{float:"left", marginRight:"45px"}}>
                       <input type="checkbox" disabled={props.imgBase64} id="approved" name="approved"value="approved"  defaultChecked={approvedChecked} onChange={selectApproved}/>
                       <label for="approved">Approved</label>
                       </div>   
                       <div style={{float:"left"}}>
                       <input type="checkbox" disabled={props.imgBase64} id="rejected" name="rejected"value="rejected"  defaultChecked={rejectedChecked} onChange={selectRejected}/>
                       <label for="rejected">Rejected/NA</label>
                       </div>  
                       <br>
                       </br>
                   </div>
                   )}                             
               </div>        
            )}

               <div className="container my-3" > 
                    <div className="input-group my-3" style={{ fontSize: "13px" }}>
                       <div>Or you can select local image to detect</div>
                    </div> 
                    <div className="input-group" style={{ fontSize: "12px" }}>
                        <div className="custom-file" style={{ zIndex: "1",position:"relative" }}>
                            <label className="custom-file-label" htmlFor="inputGroupFile01" style={{whiteSpace:"nowrap",overflow:'hidden'}}>
                                {fileLabelText}
                            </label>
                            <input
                                type="file"
                                className="custom-file-input"
                                id="inputGroupFile01"
                                multiple="file-input" 
                                onChange={selectFile}
                            />  
                        </div>      
                    </div>
                
                    {(currentFile || selectedSamplebase64 || selectedURL || URLInput) && (
                        <div className="form-group my-1">
                        <div className="progress">
                            <div
                            className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                            >
                            {progress}%
                            </div>
                        </div>
                    </div>
                    )}         
                </div>


                <div className="container my-3">
                    <button className="btn btn-success" disabled={(!selectedFiles || !selectedType )  && (( !props.imgBase64) || !selectedType ) && ((!props.docURL) || !selectedType) && (( !URLInput ) || !selectedType )} onClick={CheckBeforeUpload} >
                        Inference
                    </button>  &nbsp;
                </div>
                {((props.imgBase64 && props.cleanDetectionStatus) || (selectedFiles && props.cleanDetectionStatus ) || (URLInput && props.cleanDetectionStatus))&& (
                <div className="alert alert-sucess" role="alert" style={{padding: ".35rem 1rem"}} >
                    {message}
                </div>
                )} 
                 {((props.imgBase64 && props.cleanDetectionStatus) || (selectedFiles && props.cleanDetectionStatus ) || (URLInput && props.cleanDetectionStatus))&& (
                <div className="alert alert-sucess" role="alert" style={{padding: ".35rem 1rem"}}>
                    {messageDet}
                </div>
                )}
                {((props.imgBase64 && props.imgDetection) || (selectedFiles && props.imgDetection) || (URLInput && props.imgDetection)) && (
                <div>
                    {(!annotationIsNull && (
                    <div id="select_deselect_all" style={{display: "flex", alignItems: "center"}}>
                
                <button class="custom-button select-all" onClick={selectAll}>Select All</button>
                <button class="custom-button deselect-all" onClick={deselectAll}>Deselect All</button>
                <div id="label-container" style={{display: "flex", alignItems: "center"}}>
                       <input type="checkbox"  id="displayLabel" name="displayLabel"value="displayLabel"  defaultChecked={labelDisplayed} onChange={selectLabelDisplayed}/>
                       <label for="displayLabel" style={{marginLeft: "85px", cursor: "pointer"}} >Display Label Name</label>
                       </div>  
                       </div>
                
               
                 ))}
                <div id="buttonContainer"></div>
                </div>
                )}
            </div>
            
    );
}
export default FileUploader;






