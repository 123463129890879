import axios from "axios";
import React, { useState} from "react"
//import BackendAddress from './components/FileUploader'

//let host = "http://"+window.location.host;
//let host = "https://vi.mdop.ericsson.com:32054" //with security
//let host = "http://vi.mdop.ericsson.com:32054"
//let host = "http://150.236.157.7:32054
//let http;
//const host = process.env.HOST_SERVER;
//let host = "http://127.0.0.1:31000"
/*

function handleURL() {
const [baseurl, setBaseURL] = useState();
setBaseURL(window.BackendAddress);
let host = baseurl;

return host
}

 export class configSet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    backend: "http://10.41.82.17:31000"
    }
    
  }
  componentDidMount() {
    this.setState({ backend: "http://10.41.82.17:31000" });
  componentDidUpdate(prevProps, prevState) {

  }
  }
}
*/
window.host = getCookie("backendAddress")
if (window.host === null){
  window.host = "https://visual.intelligence.eritop.ericsson.net"
  console.log(window.host)
}

function setCookie(name,value,expiredays)
  {
    var exp = new Date()
    //console.log(exp.getTime())
    exp.setTime(exp.getTime() + expiredays*24*60*60*10000);
    console.log(exp.toGMTString());
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
    console.log(document.cookie)

  }

function getCookie(name)
{
  var arr,reg= new RegExp("(^| )" +name+"=([^;]*)(;|$)");
  
  if(arr=document.cookie.match(reg)){
    console.log(decodeURIComponent(arr[2]))
    return decodeURIComponent(arr[2]);
    /*
    var cookie = new Object();
    var temp = decodeURIComponent(arr[2])
    var strs = temp.split("&");
    for(var i=0; i<strs.length;i++)
    {
      cookie[strs[i].split("=")[0]] = decodeURIComponent(strs[i].split("=")[1])
    }
    return cookie; 
    */
  }

  else
    console.log('null')
    return null;
}



function HandleConfig ()
{
  const [backendAddress, setBackend] = useState(window.host);
  const [config,setConfig] = useState(undefined)
  
  function handleClick(){
    //console.log(process.env)
    setConfig(true)
   }
   function handleSubmit(){
    setConfig(false)
    console.log(backendAddress)
    window.host = backendAddress
    let BACKEND = 'backendAddress'
    setCookie(BACKEND,backendAddress,60)
    getCookie(BACKEND)
    //document.cookie = "backend = http://10.41.82.17:31000"
    //console.log(document.cookie)
    /*
    http = axios.create({
      // baseURL: hostname+":5000",
      baseURL: backendAddress,
      headers: {
        "Content-type": "application/json"
      },
      withCredentials: false
    });
    console.log(http)
    */
    
   }
   const handleChange = event => {
    setBackend(event.target.value);
    //console.log(backendAddress)

  };
 
  return (
    <div class="dropdown">
      <div className="container my-3">
                    
                    <button class="dropbtn" style ={{cursor: "pointer"}} onClick={handleClick} >
                        config
                    </button>  &nbsp;
                    
                    
                {config && <button class="dropbtn" style={{float: "right"}} onClick={handleSubmit} >
                        submit
                    </button> } &nbsp;
                    
                    {config && <div class = "config" style={{float: "right", marginLeft: "0px",marginTop:"-6px"}} >
                     <input 
                        list="backend_list"
                        type="search"
                        id="config"
                        name="config"
                        onChange={handleChange}
                        value={backendAddress}
                        style={{inlineSize: "250px"}}
                   />  
                    <datalist id ="backend_list" style={{inlineSize:"180px"}}>
                    <option value="https://visual.intelligence.eritop.ericsson.net">MMEA</option>
                    </datalist>

                    
                    </div>
                   
              }
                         
                </div>
                </div>
   
  )

}

// let hostname = "http://"+window.location.hostname;
/*
http = axios.create({
    // baseURL: hostname+":5000",
    baseURL: host,
    headers: {
      "Content-type": "application/json"
    },
    withCredentials: false
  });
console.log(http)
  */

export {HandleConfig}
//export default http
