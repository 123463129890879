import React from 'react';
import './App.css';
import OriginView from './OriginView';
import "bootstrap/dist/css/bootstrap.min.css";
import './components/FileUploader'
import FileUploader from './components/FileUploader';
import { HandleConfig } from './http-common';
import './node_modules/@eds/vanilla/eds.min.css';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      imgUpload: undefined,
      imgDetection: undefined,
      imgNum: undefined,
      imgSampleSelect: undefined,
      imgBase64data: undefined,
      docURL: undefined,
      intervalId: undefined,
      fileNamedata: undefined,
      cleanDetectionStatus:undefined,
      selectApproved:false,
      selectRejected:false,
      fileTypeArray:[],
      screenShotCount: 0,
      videoURL: "",
      //buttonState:{},
      selectedLabels:[],
      labelDisplayed:true
    }
    this.handleFileSelected = this.handleFileSelected.bind(this)
    this.handleImgSampleSelected = this.handleImgSampleSelected.bind(this)
  }
  componentDidMount() {
  }
  componentWillUnmount() {
    if (this.state.intervalId !== undefined) {
      clearInterval(this.state.intervalId)
    }
  }
  
  handleApproved = (checkedForApproved) =>{
    this.setState({selectApproved:checkedForApproved})
    console.log(this.state.selectApproved)
  }

  handleRejected = (checkedForRejected) =>{
    this.setState({selectRejected:checkedForRejected})
    console.log(this.state.selectRejected)
  }
  
  handleScreenshotCount = (responseScreenShotCount) =>{
    this.setState({ screenShotCount: responseScreenShotCount})
    console.log(this.state.screenShotCount);
  }
  
  handleFileSelected = (file) => {
    this.setState({ imgDetection: undefined });
    this.setState({ imgUpload: file });
    this.setState({imgSampleSelect: undefined});
    this.setState({ docURL: undefined});
    this.setState({ imgNum: file.length})
    this.setState({ fileTypeArray: []});
    this.setState({ screenShotCount: 0})
    this.setState({ videoURL: ""})
    
    const newFileTypeArray = [];
    for (let i = 0; i < file.length; i++) {
      const file_a = file[i];
      const fileType = file_a.type;
      newFileTypeArray.push(fileType);
    }
    this.setState({ fileTypeArray: newFileTypeArray }, () => {
      console.log(this.state.fileTypeArray); // 
    });  
  }

  handleImgSampleSelected = (Sample) => {
    this.setState({ imgDetection: undefined });
    this.setState({ imgSampleSelect: Sample });
    this.setState({imgUpload: undefined});
    this.setState({ videoURL: ""});
    //this.setState({ isFirstRender: true})
    console.log(this.state.imgUpload);
  }

  handleImgBase64 = (base64) => {
    this.setState({ imgBase64data: base64 });  
  }
 
  handleDocURL = (URL) => {
    this.setState({ docURL: URL})
    console.log(URL);
  }

  handleVideoURL = (URL) => {
    this.setState({ videoURL: URL})
    this.setState({imgUpload: undefined});
    this.setState({ imgDetection: undefined });
    this.setState({ screenShotCount: 0})
    console.log(URL);
  }
 handelClickedButtons =(buttonState) => {
    this.setState({ selectedLabels: buttonState});
    console.log(this.state.selectedLabels);
 }
  handleCleanDetection = () => {
    this.setState({ imgDetection: undefined });
    this.state.imgDetection=undefined;
    console.log(this.state.imgDetection);
  }

  handleCleanBase64 = () => {
    this.setState({ imgBase64data: undefined });
    this.state.imgBase64data=undefined;
    this.setState({selectedLabels: []})
  }
  
  handleLabelDisplayed=(labelDisplayed)=> {
    this.setState({labelDisplayed: labelDisplayed })
    console.log(this.state.labelDisplayed);
  }
  handleCleanURL = () => {
    this.setState({docURL: undefined});
    this.state.docURL=undefined;
    console.log(this.state.docURL);
  }
  handleFileName = (fileName) => {
    this.setState({ fileNamedata: fileName });
    console.log(fileName)
     }

  onPostResult = (response) => {
    if (response.status === 200) {
      if (response.data['jsonData']){
        this.setState({ imgDetection: response.data['jsonData']['result']})
      }
      else {
        this.setState({ imgDetection: response.data['data'] })
      }
    }
  }

  render() {
    return (
          <body class= "light">
            <div id= "root">
            <header class="sysbar">
              <div class="items-container">
                <div class="item" style={{ color: "#FFFFFF" }}>
                  <i class="icon icon-econ"></i>
                  <span class="product" >Visual</span>
                  <span class="product" >Intelligence</span>
                  <span class="acronym">EDS</span>     
                </div>
              </div>
              <div className="container" style={{marginLeft:"300px", alignItems:"center"}} >
                   <HandleConfig/>
                  </div>
            </header>
            <main className="App-Main">
              <div className="container" style={{ width: "450px", overflowY: 'auto' }}>
                <FileUploader onPostResponse={this.onPostResult} onFileSelect={this.handleFileSelected} onSampleSelect={this.handleImgSampleSelected} imgDetection={this.state.imgDetection} imgBase64={this.state.imgBase64data} docURL={this.state.docURL} onFileNameSelect={this.handleFileName} onScreenShotCount={this.handleScreenshotCount} onCleanDetection={this.handleCleanDetection} onButtonState={this.handelClickedButtons} onVideoURL={this.handleVideoURL}  cleanDetectionStatus={this.state.imgDetection} onCleanBase64={this.handleCleanBase64} onCleanURL={this.handleCleanURL} onSelectApproved={this.handleApproved} onSelectRejected={this.handleRejected} onSelectLabelDisplayed={this.handleLabelDisplayed}/>
              </div>
              <div id="origin" className="App-OriginImg">
                <OriginView imgSrc={this.state.imgUpload} imgNum={this.state.imgNum} imgSample={this.state.imgSampleSelect}  imgDetection={this.state.imgDetection}  videoURL={this.state.videoURL} screenShotCount={this.state.screenShotCount} onimgBase64={this.handleImgBase64} ondocURL={this.handleDocURL} labelDisplayed={this.state.labelDisplayed} selectedLabels={this.state.selectedLabels} onCleanDetection={this.handleCleanDetection} fileName={this.state.fileNamedata} selectedLabels={this.state.selectedLabels}  onCleanBase64={this.handleCleanBase64} onCleanURL={this.handleCleanURL} approved={this.state.selectApproved} rejected={this.state.selectRejected} fileTypeArray={this.state.fileTypeArray}/>
              </div>
            </main>
            <footer className="App-Footer">
            </footer>
            </div>
            </body>
        );
      }
    }


export default App;
