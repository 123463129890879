/* eslint-disable default-case */
/* eslint-disable no-duplicate-case */
//import http from "../http-common";
import axios from "axios";

const uploadDataAndFile1 = (file, imageName, option, market, sectorID, bandInfo, onUploadProgress) => {

  const groupingUC = ['Caution_Tape_Checking_Det','PPE_Rigger_Det','PPE_Electrical_Det','Hand_Held_Tools_Checking_Det','Vehicle_Light_Windshield_Licenseplate_Det',
  'Vehicle_Tyre_Det','Vehicle_Airbags_Det','Vehicle_Seatbelts_Det','First_Aid_Box_Det','Fire_Extinguisher_Det','Rigging_Kit_Checking_Det','Antenna_Grounding_Grouping_2_Det',
  'Radio_Grounding_Grouping_2_Det','Rigging_Kit_1_Pulley_Det','Rigging_Kit_2_Pulley_Det','Rigging_Kit_3_Pulley_Det','GPS_Grounding_Grouping_2_Det','Radio_Mounting_Grouping_3_Det',
  'Antenna_Mounting_Grouping_3_Det','Baseband_Mounting_Grouping_3_Det','Baseband_Grounding_Grouping_3_Det','PPE_Rigger_Without_Vest_Det','Crane_Drop_Zone_Det',
  'Crane_Position_Det','MW_Dish_Grounding_G2_Det','SRS_Screenshot_Grouping_4_Det','Cabinet_Leveling_Grouping_3_Det','TRM_Mounting_Grouping_3_Det','Baseframe_Cabinet_Mounting_Grouping_6_Det',
  'LLM_Antenna_Azimuth_Validation_Det','RBS_Main_Grounding_Grouping_2_Det','Baseband_Mounting_And_Unused_Port_Checking_Grouping_3_Det','Earthing_Kit_And_Grounding_Grouping_5_Det','MW_RAU_Weatherproofing_Grouping_N_Det',
  'Indoor_TRM_Mounting_And_Unused_Ports_Checking_Grouping_3_Det','MW_Dish_Small_Size_Mounting_Grouping_7_Det','MW_RAU_Grounding_Grouping_N_Det','MW_Dish_Arm_Mounting_G4_Det']
  var json1 =

      { data: {
         ndarray: []
         }
      }
      let url = "/api/v1/vi";
      let inference_type = "";
      let infTypeList = [];
      let request_Info = { sessionID: "NA", marketArea: "NA", customer: "NA", country: "NA", sectorId: sectorID, bandInfo: bandInfo}; 
      switch (option){
        case 'Antenna_Front_Facing_Det':
          inference_type = 'antenna-front-facing-detection';
          url = '/api/v1/antenna';
          break
        case 'Antenna_Coverage_Area_Det':
          inference_type = 'antenna-coverage-area';
          url = '/api/v1/antenna';
          break
        case 'PPE_Safety_Checking_Det':
          inference_type = 'ppe-safety-checking'
          url = '/api/v1/ehs'
          break
        case 'PPE_Helmet_Vest_Shoe_Det':
          inference_type = 'ppe-helmet-vest-shoes'
          url = '/api/v1/ehs'
          break
        case 'PPE_Helmet_Harness_Gloves_Shoes_Det':
          inference_type = 'ppe-helmet-harness-gloves-shoes'
          url = '/api/v1/ehs'
          break
        case 'PPE_Rigger_Det':
          inference_type = 'ppe-rigger'
          url = '/api/v1/ehs'
          break
        case 'PPE_Electrical_Det':
          inference_type= 'ppe-electrical'
          url = '/api/v1/ehs'
          break
        case 'Hand_Held_Tools_Checking_Det':
          inference_type = 'hand-held-tools-checking'
          url = '/api/v1/ehs'
          break
        case 'Vehicle_Light_Windshield_Licenseplate_Det':
          inference_type = 'vehicle-light-windshield-licenseplate'
          url = '/api/v1/ehs'
          break
        case 'Vehicle_Odometer_Det':
          inference_type = 'vehicle-mileage'
          url = '/api/v1/ehs'
          break
        case 'Vehicle_Tyre_Det':
          inference_type = 'vehicle-tyres'
          url = '/api/v1/ehs'
          break
        case 'Vehicle_Airbags_Det':
          inference_type = 'vehicle-airbags'
          url = '/api/v1/ehs'
          break
        case 'Vehicle_Seatbelts_Det':
          inference_type= 'vehicle-seatbelts'
          url = '/api/v1/ehs'
          break
        case 'First_Aid_Box_Det':
          inference_type = 'first-aid-box'
          url = '/api/v1/ehs'
          break
        case 'Fire_Extinguisher_Det':
          inference_type = 'fire-extinguisher'
          url = '/api/v1/ehs'
          break
        case 'Radio_Weatherproofing_Det':
          inference_type = 'radio-weatherproofing'
          request_Info = { sessionID: "NA", marketArea: market, customer: "NA", country: "NA"};
          url = '/api/v1/radio';
          break
        case 'Antenna_Mounting_Torque_Mark_Checking_Red':
          inference_type = 'mounting-torque-mark-checking-red'
          url = '/api/v1/antenna';
          break
        case 'Antenna_Mounting_Torque_Mark_Checking_Yellow':
          inference_type = 'mounting-torque-mark-checking-yellow'
          url = '/api/v1/antenna';
          break
        case 'Antenna_Mounting_Torque_Mark_Checking_Blue':
          inference_type = 'mounting-torque-mark-checking-blue'
          url = '/api/v1/antenna';
          break
        case 'Antenna_Mounting_Torque_Mark_Checking_Green':
          inference_type = 'mounting-torque-mark-checking-green'
          url = '/api/v1/antenna';
          break
        case 'Antenna_Mounting_Torque_Mark_Checking_Black':
          inference_type = 'mounting-torque-mark-checking-black'
          url = '/api/v1/antenna';
          break
        case 'Antenna_Mounting_Torque_Mark_Checking_Det':
          inference_type = 'mounting-torque-mark-checking'
          url = '/api/v1/antenna';
          break
        case 'Antenna_Weatherproofing_Det':
          inference_type = 'antenna-weatherproofing'
          request_Info = { sessionID: "NA", marketArea: market, customer: "NA", country: "NA"};
          url = '/api/v1/antenna';
          break
        case 'Antenna_Unused_RF_Port_Weatherproofing_Det':
          inference_type = 'antenna-unused-rf-port-weatherproofing'
          url = '/api/v1/antenna';
          break
        case 'Radio_Unused_RF_Port_Weatherproofing_Det':
          inference_type = 'radio-unused-rf-port-weatherproofing'
          url = '/api/v1/radio';
          break
        case 'Antenna_Unused_RF_Port_Count_Det':
          inference_type = 'antenna-unused-rf-port-count'
          url = '/api/v1/antenna';
          break
        case 'Antenna_Unused_Port_Sealing_Det':
          inference_type = 'antenna-unused-port-sealing'
          url = '/api/v1/antenna';
          break
        case 'Radio_Unused_Port_Sealing_Det':
          inference_type = 'radio-unused-port-sealing'
          url = '/api/v1/radio';
          break
        case 'Radio_Unused_RF_Port_Count_Det':
          inference_type = 'radio-unused-rf-port-count'
          url = '/api/v1/radio';
          break
        case 'Antenna_Mounting_Det':
          inference_type = 'antenna-mounting'
          url = '/api/v1/antenna';
          break
        case 'Radio_Mounting_Det':
          inference_type = 'radio-mounting'
          url = '/api/v1/radio';
          break
        case 'Baseband_Mounting_Det':
          inference_type = 'baseband-mounting'
          url = '/api/v1/baseband';
          break
        case 'Baseband_Grounding_Det':
          inference_type = 'baseband-grounding'
          url = '/api/v1/baseband';
          break
        case 'Baseband_Mounting_Ext_Det':
          inference_type = 'baseband-mounting-ext'
          url = '/api/v1/baseband';
          break
        case 'Baseband_Grounding_Ext_Det':
          inference_type = 'baseband-grounding-ext'
          url = '/api/v1/baseband';
          break
        case 'GPS_Mounting_Det':
          inference_type = 'gps-mounting'
          url = '/api/v1/gps';
          break
        case 'GPS_Cable_Routing_Det':
          inference_type = 'gps-cable-routing'
          url = '/api/v1/gps';
          break
        case 'GPS_Antenna_Grounding_Det':
          inference_type = 'gps-antenna-ground-termination'
          request_Info = { sessionID: "NA", marketArea: market, customer: "NA", country: "NA"};
          url = '/api/v1/gps';
          break
        case 'GPS_Main_Grounding_Det':
          inference_type = 'gps-main-ground-termination'
          url = '/api/v1/gps';
          break
        case 'GPS_Weatherproofing_Det':
          inference_type = 'gps-weatherproofing'
          url = '/api/v1/gps';
          break
        case 'GPS_Mounting_Det':
          inference_type = 'gps-mounting'
          url = '/api/v1/gps';
          break
        case 'Roxtec_Defect_Det':
          inference_type = 'roxtec-defect-detection'
          url = '/api/v1/roxtec';
          break
        case 'Site_Data_Extraction_From_Image_Det':
          inference_type = 'site-data-extraction-from-image'
          request_Info = { sessionID: "NA", marketArea: market, customer: "NA", country: "NA"};
          url = '/api/v1/ocr';
          break
        case 'Thermometer_Reading_Det':
          inference_type = 'thermometer-reading'
          url = '/api/v1/ehs';
          break
        case 'Battery_Defect_Det':
          inference_type = 'battery-defect-detection'
          url = '/api/v1/battery';
          break
        case 'Door_Status_Checking_Det':
          inference_type = 'door-corrosion-detection'
          url = '/api/v1/door';
          break
        case 'Tower_Installed_Equipment_Recognition_Det':
          inference_type = 'tower-installed-equipment-recognition'
          url = '/api/v1/tower';
          break
        case 'Tower_Installed_Equipment_Defect_Det':
          inference_type = 'tower-installed-equipment-defect-detection'
          url = '/api/v1/tower';
          break
        case 'Tower_Type_Recognition_Det':
          inference_type = 'tower-type-recognition'
          url = '/api/v1/tower';
          break
        case 'Meter_Reading_Det':
          inference_type = 'meter-reading'
          url = '/api/v1/ocr';
          break
        case 'Nameplate_Data_Recognition_Det':
          inference_type = 'nameplate-reading'
          url = '/api/v1/ocr';
          break
        case 'Cabinet_Installed_Equipment_Recognition_Det':
          inference_type = 'cabinet-installed-equipment-recognition'
          url = '/api/v1/cabinet';
          break
        case 'Cabinet_Installed_Equipment_Defect_Det':
          inference_type = 'cabinet-installed-equipment-defect-detection'
          url = '/api/v1/cabinet';
          break
        case 'Worker_Identification_Det':
          inference_type = 'face-recognition'
          url = '/api/v1/ehs';
          break
        case 'Blurred_Image_Det':
          inference_type = 'blurred-image-detection'
          url = '/api/v1/image';
          break
        case 'Level_Check_Det':
          inference_type = 'level-check'
          url = '/api/v1/level-check';
          break
        case 'Loose_Hardware_Det':
          inference_type= 'loose-hardware'
          url = '/api/v1/hardware';
          break
        case 'Unistrut_End_Cap_Det':
          inference_type = 'unistrut-end-cap'
          url = '/api/v1/unistrut';
          break
        case 'Manufacture_Label_Reading_Det':
          inference_type = 'manufacture-label-reading'
          url = '/api/v1/ocr';
          break
        case 'Caution_Tape_Checking_Det':
          inference_type = 'caution-tape-checking'
          request_Info = { sessionID: "NA", marketArea: market, customer: "NA", country: "NA"};
          url = '/api/v1/ehs';
          break
        case 'Rigging_Kit_Checking_Det':
          inference_type = 'rigging-kit-checking'
          url = '/api/v1/ehs';
          break
        case 'Baseband_GPS_Cable_Ext_Det':
          inference_type = 'baseband-gps-cable-ext'
          url = '/api/v1/baseband';
          break
        case 'Baseband_Unused_Port_Sealing_Det':
          inference_type = 'baseband-unused-port-sealing'
          url = '/api/v1/baseband';
          break
        case 'Antenna_Grounding_Grouping_2_Det':
          inference_type = 'antenna-grounding-g2'
          url = '/api/v1/antenna';
          break
        case 'Radio_Grounding_Grouping_2_Det':
          inference_type = 'radio-grounding-g2'
          url = '/api/v1/radio';
          break
        case 'Rigging_Kit_1_Pulley_Det':
          inference_type = 'rigging-kit-1-pulley'
          url = '/api/v1/ehs';
          break
        case 'Rigging_Kit_2_Pulley_Det':
          inference_type = 'rigging-kit-2-pulley'
          url = '/api/v1/ehs';
          break
        case 'Rigging_Kit_3_Pulley_Det':
          inference_type = 'rigging-kit-3-pulley'
          url = '/api/v1/ehs';
          break
        case 'Emergency_Plan_Banner_Det':
          inference_type = 'emergency-plan-banner'
          url = '/api/v1/ehs';
          break
        case 'GPS_Grounding_Grouping_2_Det':
          inference_type = 'gps-grounding-g2'
          url = '/api/v1/gps';
          break
        case 'Radio_Mounting_Grouping_3_Det':
          inference_type = 'radio-mounting-g3'
          url = '/api/v1/radio';
          break
        case 'Antenna_Mounting_Grouping_3_Det':
          inference_type = 'antenna-mounting-g3'
          url = '/api/v1/antenna';
          break
        case 'Baseband_Mounting_Grouping_3_Det':
          inference_type = 'baseband-mounting-g3'
          url = '/api/v1/baseband';
          break
        case 'Baseband_Grounding_Grouping_3_Det':
          inference_type = 'baseband-grounding-g3'
          url = '/api/v1/baseband';
          break
        case 'PPE_Helmet_Shoes_Det':  
          inference_type = 'ppe-helmet-shoes'
          url = '/api/v1/ehs';
          break
        case 'PPE_Rigger_Without_Vest_Det':  
          inference_type = 'ppe-rigger-without-vest'
          url = '/api/v1/ehs';
          break
        case 'Power_Supply_Unit_Mounting_Det':  
           inference_type = 'power-supply-unit-mounting'
          url = '/api/v1/power-supply-unit';
          break
        case 'Crane_Drop_Zone_Det':  
           inference_type = 'crane-drop-zone'
          url = '/api/v1/ehs';
          break
        case 'Crane_Position_Det':  
          inference_type = 'crane-position'
          url = '/api/v1/ehs';
          break
        case 'Vin_Extraction_Det':  
          inference_type = 'vin-extraction'
          url = '/api/v1/ehs';
          break
        case 'Generic_OCR_Det':  
          inference_type = 'generic-ocr'
          url = '/api/v1/ocr';
          break
        case 'Cable_Clamp_Checking_Det':  
          inference_type = 'cable-clamp-checking'
          url = '/api/v1/hardware';
          break
        case 'Safe_Work_Info_Verify_Det':  
          inference_type = 'safe-work-info-verify'
          url = '/api/v1/ehs';
          break
        case 'Zeus_TowerID_Extraction_Det':  
          inference_type = 'zeus-towerid-extraction'
          url = '/api/v1/ocr';
          break
        case 'Grounding_Busbar_Det':  
          inference_type = 'grounding-busbar'
          url = '/api/v1/hardware';
          break
        case 'Tilt_Meter_Presence_Det':  
          inference_type = 'tilt-meter-presence'
          url = '/api/v1/level-check';
          break
        case 'QR_And_Barcode_Reader_Det':  
          inference_type = 'barcode-reader'
          url = '/api/v1/ocr';
          break
        case 'Passive_Tagging_Det':  
          inference_type = 'passive-tagging'
          url = '/api/v1/hardware';
          break
        case 'Antenna_Label_Reading_Det':  
          inference_type = 'antenna-label-reading'
          url = '/api/v1/ocr';
          break
        case 'Sticker_Reading_Det':  
          inference_type = 'sticker-reading'
          url = '/api/v1/ocr';
          break
        case 'Circuit_Breaker_Connection_Det':  
          inference_type = 'circuit-breaker-connection'
          request_Info = { sessionID: "NA", marketArea: market, customer: "NA", country: "NA"};
          //url = '/api/v1/cabinet';//for seldon, it's this url
          break
        case 'QR_And_Barcode_Available_Det':  
          inference_type = 'barcode-presence'
          url = '/api/v1/ocr';
          break
        case 'Busbar_Front_Det':  
          inference_type = 'busbar-front'
          url = '/api/v1/hardware';
          break
        case 'MW_Dish_Grounding_G2_Det':  
          inference_type = 'mw-dish-grounding-g2'
          url = '/api/v1/antenna';
          break
        case 'SRS_Screenshot_Grouping_4_Det':  
          inference_type = 'srs-screenshot-detection-g4'
          url = '/api/v1/ocr';
          break
        case 'Color_Sticker_Det':  
          inference_type = 'radio-color-sticker'
          url = '/api/v1/radio';
          break
        case 'Jumper_Color_Code_Det':  
          inference_type = 'jumper-color-code'
          url = '/api/v1/radio';
          break
        case 'Azimuth_Reading_Det':  
          inference_type = 'azimuth-reading'
          url = '/api/v1/antenna';
          break
        case 'Cable_Tie_Checking_Det':  
          inference_type = 'cable-tie-checking'
          url = '/api/v1/hardware';
          break
        case 'Cabinet_Leveling_Grouping_3_Det':  
          inference_type = 'cabinet-leveling-g3'
          url = '/api/v1/cabinet';
          break
        case 'Baseframe_Cabinet_Mounting_Grouping_6_Det':  
          inference_type = 'baseframe-cabinet-mounting-g6'
          url = '/api/v1/cabinet';
          break
        case 'Sector_Assembly_Det':  
          inference_type = 'sector-assembly'
          url = '/api/v1/tower';
          break
        case 'Antenna_Height_Reading_Det':  
          inference_type = 'antenna-height-reading'
          url = '/api/v1/ocr';
          break
        case 'Tape_Measure_Presence_Det':  
          inference_type = 'tape-measure-presence'
          url = '/api/v1/antenna';
          break
        case 'Cable_Run_Checking_Det':  
          inference_type = 'cable-run-checking'
          url = '/api/v1/hardware';
          break
        case 'MCB_Detection_Det':  
          inference_type = 'mcb-detection'
          url = '/api/v1/cabinet';
          break
        case 'TRM_Mounting_Grouping_3_Det':  
          inference_type = 'trm-mounting-g3'
          url = '/api/v1/cabinet';
          break
        case 'MW_Dish_Mounting_Det':  
          inference_type = 'mw-dish-mounting'
          url = '/api/v1/antenna';
          break
        case 'DDF_Detection_Det':  
          inference_type = 'ddf-detection'
          url = '/api/v1/cabinet';
          break
        case 'LLM_Antenna_Azimuth_Validation_Det':  
          inference_type = 'llm-c-antenna-azimuth-validation'
          //url = '/api/v1/antenna';
          break
        case 'Site_Entrance_Checking_Det':  
          inference_type = 'site-entrance-checking'
          break
        case 'Battery_Presence_Det':  
          inference_type = 'battery-presence'
          url = '/api/v1/cabinet';
          break
        case 'KWH_Meter_Presence_Det':  
          inference_type = 'kwh-meter-presence'
          break
        case 'Site_Access_Detection_Det':  
          inference_type = 'site-access-detection'
          break
        case 'Foundation_Of_Cabinet_Det':  
          inference_type = 'foundation-of-cabinet'
          url = '/api/v1/cabinet';
          break
        case 'Cabling_Inside_Cabinet_Det':  
          inference_type = 'cabling-inside-cabinet'
          url = '/api/v1/cabinet';
          break
        case 'PPE_Rigger_Video_Det':  
          inference_type = 'ppe-rigger-video'
          break
        case 'PPE_Rigger_And_Safety_Checking_Video_Det':  
          infTypeList.push("ppe-rigger-video", "ppe-safety-checking-video");
          break
        case 'Rectifier_Detection_Det':  
          inference_type = 'rectifier-detection'
          url = '/api/v1/cabinet';
          break
        case 'LLM_Site_Access_Detection_Det':  
          inference_type = 'llm-s-site-access-detection'
          break
        case 'RBS_Main_Grounding_Grouping_2_Det':  
          inference_type = 'rbs-main-grounding-g2'
          break
        case 'Vertical_Power_Cable_Color_Code_Det':  
          inference_type = 'vertical-power-cable-color-code-presence'
          break
        case 'Cable_Inlet_Presence_Det':  
          inference_type = 'cable-inlet-presence'
          break
        case 'Baseband_Mounting_And_Unused_Port_Checking_Grouping_3_Det':  
          inference_type = 'baseband-mounting-and-unused-port-checking-g3'
          break
        case 'Antenna_Weatherproofing_And_Unused_Port_Sealing_Det':  
          inference_type = 'antenna-weatherproofing-and-unused-port-sealing'
          break
        case 'Radio_Weatherproofing_And_Unused_Port_Sealing_Det':  
          inference_type = 'radio-weatherproofing-and-unused-port-sealing'
          break
        case 'Earthing_Kit_And_Grounding_Grouping_5_Det':  
          inference_type = 'earthing-kit-and-grounding-g5'
          break
        case 'MW_RAU_Weatherproofing_Grouping_N_Det':  
          inference_type = 'mw-rau-weatherproofing-gn'
          break
        case 'Radio_Grounding_Det':  
          inference_type = 'radio-grounding'
          break
        case 'Indoor_TRM_Mounting_And_Unused_Ports_Checking_Grouping_3_Det':  
          inference_type = 'indoor-trm-mounting-and-unused-ports-checking-g3'
          break
        case 'MW_Dish_Small_Size_Mounting_Grouping_7_Det':  
          inference_type = 'mw-dish-small-size-mounting-g7'
          break
        case 'PPE_Climbing_Checking_Video_Det':  
          inference_type = 'climbing-checking-video'
          break
        case 'MW_RAU_Grounding_Grouping_N_Det':  
          inference_type = 'mw-rau-grounding-gn'
          break
        case 'LLM_Spare_1_Det':  
          inference_type = 'llm-s-spare-1'
          break
        case 'MW_Dish_Arm_Mounting_G4_Det':  
          inference_type = 'mw-dish-arm-mounting-g4'
          break
        
      }
      if (groupingUC.includes(option) || (!(file instanceof Array))){
        var imgInfo = new Object();  
        imgInfo.name = imageName;
        if (inference_type !== ""){
        imgInfo.inf_type = inference_type;
        }
        if(infTypeList.length > 0){
          imgInfo.infTypeList = infTypeList;
        }
        imgInfo.requestInfo = request_Info;
        if (file instanceof Array){
          imgInfo.imageList = file;
        }
        else if (file.length >2000){
          imgInfo.imageBase64 = file;
          }
        else if (file.length <= 2000){
            imgInfo.imageUrl = file; 
          }
          console.log(imgInfo);
          json1.data.ndarray.push(imgInfo);
      }
      else if (!groupingUC.includes(option) && (file.length > 1)){
        var imgInfoArray = [];
        for (let i=0;i<file.length;i++){
          var imgInfo = new Object();
          imgInfo.name = file[i].name;
          imgInfo.imageBase64 = file[i].imageBase64;
          imgInfo.requestInfo = request_Info;
          imgInfo.inf_type = inference_type;
          imgInfoArray.push(imgInfo)
      }    
          console.log(imgInfoArray);
          json1.data.ndarray = (imgInfoArray);
    }
    
        var jsonData = JSON.stringify(json1)
        console.log(jsonData)

  //String token = "";
  console.log(window.host)
 let http = axios.create({
    // baseURL: hostname+":5000",
    baseURL: window.host,
    headers: {
      "Content-type": "application/json"
    },
    withCredentials: false
  });
  return http.post(url, jsonData, {
    headers: {
      "Content-Type": "application/json",
      "E-Top": "qAhENO3QuvN8AIGGKaCVKdSX3XP2BWEe",//comment this line for 8217
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, E-Top"//need this line for MMEA portal, comment this line for 8217.
    },
    onUploadProgress,
  });
};

export default {
  //uploadDataAndFile,
  uploadDataAndFile1
};
